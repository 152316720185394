import React from 'react'
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import {iabModel} from "../../model/iabModel.js";
import {localstorageModel} from "../../model/localstorageModel.js";

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 14},
};

const iabOptions = iabModel.data.map(item => {
    return {
        label: item.name,
        value: item.id
    }
})

export const SectionModal = (props) => {
    let lang = 'name_'+localstorageModel.language;

    const {createSectionModal, updateSectionModal, onCancel, updateSectionFields, onSubmitSectionModal} = props

    console.log('SectionModal props', props)

    const parentsOptions = updateSectionModal == false ? iabModel.data.map(el => {
        return {
            label:
                `${el?.tier_1 !== null ? el?.tier_1[lang] : ''}${el?.tier_2 !== null ? ` > ${el?.tier_2[lang]}` : ''}
                ${el?.tier_3 !== null ? ` > ${el?.tier_3[lang]}` : ''}${el?.tier_4 !== null ? ` > ${el?.tier_4[lang]}` : ''}`
                ?
                `${el?.tier_1!== null ? el?.tier_1[lang] : ''}${el?.tier_2 !== null ? ` > ${el?.tier_2[lang]}` : ''}
                 ${el?.tier_3 !== null ? ` > ${el?.tier_3[lang]}` : ''}${el?.tier_4 !== null ? ` > ${el?.tier_4[lang]}` : ''}`
                : '-'
            ,
            value: el.id,
        }
        })
        :
            iabModel.data_lang.filter(item => item.id != updateSectionModal.id).map(el => {
            return {
                label: el['name_'+localstorageModel.language],
                value: el.id
            }
            })






    return (
        <Modal title={updateSectionModal ? "Update IAB" : "Create IAB"}
               visible={updateSectionModal || createSectionModal}
               onCancel={onCancel}
               destroyOnClose={true}
               footer={[
                   <div key={1}>
                       <Button onClick={onCancel}>
                           Cancel
                       </Button>
                       <Button form="sectionForm" type={"primary"} htmlType="submit">
                           Save
                       </Button>
                   </div>
               ]}
               width={600}>
            <Form
                id="sectionForm"
                {...layout}
                name="sectionForm"
                fields={updateSectionFields}
                onFinish={onSubmitSectionModal}
            >

                <Form.Item
                    label="Name RU"
                    name="name_ru"
                    rules={[
                        {required: true, message: 'Enter name!'},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Name EN"
                    name="name_en"
                    rules={[
                        {required: true, message: 'Enter name!'},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Name KZ"
                    name="name_kz"
                    rules={[
                        {required: true, message: 'Enter name!'},
                    ]}
                >
                    <Input/>
                </Form.Item>


                <Form.Item
                    label="Description"
                    name="description"
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>

               {/* <Form.Item
                    label="IAB"
                    name="iab_id"
                >
                    <Input />
                </Form.Item>*/}

                <Form.Item
                    label="Parent"
                    name="parent_id"
                >
                    <Select
                        options={parentsOptions}
                    />
                </Form.Item>

                {/*<Form.Item
                    label="Tier 1"
                    name="tier_1"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Tier 2"
                    name="tier_2"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Tier 3"
                    name="tier_3"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Tier 4"
                    name="tier_4"
                >
                    <Input/>
                </Form.Item>*/}
            </Form>
        </Modal>
    )
}