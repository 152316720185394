import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";

export const usersModel = makeAutoObservable({
   users: [],
   status: false,
   current_user: null,
   error: null,

   getUsers: async () => {
      try {
         const users = await instance().get('/users/').then(res => res.data)
         // check THIS FIXME
         usersModel.users = users.data
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   createUser: async (payload) => {
      try {
        const newUser =  await instance().post('/users/', payload).then(res => res.data)
        usersModel.status = 'create'
         usersModel.current_user = newUser.data.username
         await usersModel.getUsers()
         // check await? FIXME usersModel.getUsers()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   blockUser: async (id, username) => {
      try {
         await instance().post(`/users/block/${id}`).then(res => res.data)
         usersModel.status = 'blockUser'
         usersModel.current_user = username
         await usersModel.getUsers()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   unblockUser: async (id, username) => {
      try {
         await instance().post(`/users/unblock/${id}`).then(res => res.data)
         usersModel.status = 'unblockUser'
         usersModel.current_user = username
         await usersModel.getUsers()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   deleteUser: async (id, username) => {
      try {
         await instance().delete(`/users/purge/${id}`).then(res => res.data)
         usersModel.status = 'delete'
         usersModel.current_user = username
         await usersModel.getUsers()

      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   updateUser: async (payload, id) => {
      try {
         const update =  await instance().put(`/users/${id}`, payload).then(res => res.data)
         await usersModel.getUsers()
         usersModel.status = 'update'
         usersModel.current_user = update.data.name
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },

   changePasswordUser: async (payload, id) => {
      try {
         await instance().put(`/users/${id}`, payload).then(res => res.data)
         await usersModel.getUsers()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },


});
