import Form from "antd/es/form";
import Card from "antd/es/card";
import React, {useEffect} from "react";
import Select from "antd/es/select";
import Input from "antd/es/input";
import Space from "antd/es/space";
import Switch from "antd/es/switch";
import {InputNumber} from "antd";
import Row from "antd/es/row";
import Col from "antd/es/col";


const { Option } = Select;

export const ClassTemplate = (props) => {

    const {
        termTypeParams,
        termTypesForm,
        currentTermType
    } = props

    useEffect(() => {
        termTypesForm.resetFields()
    }, [termTypeParams])

    const currentObj = (field) => currentTermType.params.find(param => param.key === termTypeParams[field.fieldKey]?.key)

    return <>
        <Card
            title="Term types params"
            style={{width: '98%'}}
        >
            <Form
                style={{marginBottom: 15, width: 740}}
                id={'termTypes'}
                layout={'inline'}
                form={termTypesForm}
            >
                <Form.List name="params" initialValue={termTypeParams}>
                    {(itemInputs) => (
                        <>
                            {itemInputs.map((field) => (
                                <Space
                                    key={field.key}
                                    style={{ display: "flex", justifyContent: 'space-between', flexWrap: "wrap", marginBottom: 8 }}
                                    align="baseline"
                                >
                                    <Row>
                                        <Col>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, "name"]}
                                                fieldKey={[field.fieldKey, "name"]}
                                                style={{width: 250}}
                                                label={"Name"}
                                            >
                                                <Input disabled={true} placeholder="Item Code" />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, "value"]}
                                                fieldKey={[field.fieldKey, "value"]}
                                                label={"Value"}
                                            >
                                                {
                                                    (
                                                        currentObj(field)?.type !== 'bool' &&
                                                        currentObj(field)?.values !== null)
                                                        ?
                                                        <Select
                                                            showSearch
                                                            allowClear
                                                            placeholder="Select a value"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{width: 250}}
                                                            disabled={!currentTermType.params.find(param => param.key === termTypeParams[field.fieldKey]?.key)?.required}
                                                        >
                                                            {currentTermType.params.find(param => param.key === termTypeParams[field.fieldKey]?.key)
                                                                ?.values.map(value => <Option value={value}>{value}</Option>) }

                                                        </Select>

                                                        : (
                                                            currentObj(field)?.values === null &&
                                                            currentObj(field)?.type === 'bool') ?

                                                            <Switch
                                                                disabled={!currentTermType.params.find(param => param.key === termTypeParams[field.fieldKey]?.key)?.required}
                                                            />

                                                            : (
                                                                currentObj(field)?.values === null &&
                                                                currentObj(field)?.type === 'integer') ?

                                                                <InputNumber
                                                                    style={{width: 250}}
                                                                    disabled={!currentTermType.params.find(param => param.key === termTypeParams[field.fieldKey]?.key)?.required}
                                                                />

                                                                : (
                                                                    currentObj(field)?.values === null &&
                                                                    currentObj(field)?.type === 'string') ?

                                                                    <Input
                                                                        style={{width: 250}}
                                                                        disabled={!currentTermType.params.find(param => param.key === termTypeParams[field.fieldKey]?.key)?.required}
                                                                    />
                                                                    :
                                                                    <>
                                                                    </>
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Space>
                            ))}
                        </>
                    )}
                </Form.List>
            </Form>
        </Card>
    </>
}