import React, {useEffect, useState} from 'react'
import Layout from "antd/es/layout/layout";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import UndoOutlined from "@ant-design/icons/UndoOutlined";
import {CreateUpdateUsersModal} from "./CreateUpdateModal"
import { SideBarComponent } from "../SideBar/SideBarComponent"
import {changeFormFieldsData} from "../../tools/changeFormFieldsData";
import {DomainsList} from "./DomainsList.jsx";
import notification from "antd/es/notification";
import { observer } from 'mobx-react-lite';
import {domainModel} from "../../model/domainModel.js";
import {useTranslation} from "react-i18next";



const initialClassFields = [
    {
        name: 'id',
        value: ''
    },
    {
        name: 'name',
        value: ''
    },
    {
        name: 'type',
        value: ''
    },
    {
        name: 'manager',
        value: ''
    },
]


// export const UsersContainer = () => {
export const DomainsContainer = observer(() => {

    const [createUser, setCreateUser] = useState(null)
    const [updateUser, setUpdateUser] = useState(null)
    // const [changePasswordModal, setChangePasswordModal] = useState(null)
    const [userFields, setUserFields] = useState(initialClassFields)

    const [api, contextHolder] = notification.useNotification();

    const {t} = useTranslation()


    const DeleteNotif = (id) => {
        api.error({
            message: 'Deleted',
            description:
                `Domain zone ${id} Deleted`,
        });
    };
    const CreateNotif = (username) => {
        api.success({
            message: 'Created',
            description:
                `Domain zone ${username} created`,
        });
    };

    const UpdateNotif = (username) => {
        api.success({
            message: 'Updated',
            description:
                `Domain zone ${username} updated`,
        });
    };


    if (domainModel.status) {
        if (domainModel.status === 'delete') {
            // DeleteNotif(domainModel.current)
        }
        if (domainModel.status === 'create') {
            // CreateNotif(domainModel.current)
        }
        if (domainModel.status === 'update') {
            // UpdateNotif(domainModel.current)
        }
    }

    const onCancel = () => {
        setCreateUser(null)
        setUpdateUser(null)
        setUserFields(initialUsersFields)
    }


    const onOpenUserModal = (type, value) => {
        if (type === 'create') {
            setCreateUser(true)
        } else if (type === 'update') {
            // check rerender FIXME
            setUserFields(changeFormFieldsData(userFields, value))
            setUpdateUser(value)
        }
    }



    const onUserSubmit = (value) => {
        if (createUser) {
            const payload = value
            domainModel.create(payload)
        } else if (updateUser) {
            const payload = value
            domainModel.update(payload, updateUser.id)
        }
        onCancel()
    }

    const refresh = () => {
        window.location.reload()
    }

    const sideBarMenuItems = [
        {
            title: t('Create domain zone'),
            icon: <PlusCircleOutlined/>,
            action: () => onOpenUserModal('create'),
            // link: '/users/createUser/main',
        },
        {
            title: t('Refresh'),
            icon: <UndoOutlined/>,
            action: refresh
        }
    ]

    return (
        <>
            <SideBarComponent
                menuItems={sideBarMenuItems}
            />

            {contextHolder}
            <CreateUpdateUsersModal
                updateUser={updateUser}
                createUser={createUser}
                onCancel={onCancel}
                onSubmit={onUserSubmit}
                fields={userFields}
                history={history}
            />
            <Layout className={'contentLayout'}>
                <h2>
                    {t('Domains Zones')}
                </h2>
                <DomainsList
                    createUser={createUser}
                    setCreateUser={setCreateUser}
                    updateUser={updateUser}
                    setUpdateUser={setUpdateUser}
                    fields={userFields}
                    setFields={setUserFields}
                    onOpenModal={onOpenUserModal}
                    onCancel={onCancel}
                />
            </Layout>
        </>
    )
})
