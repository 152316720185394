import React, {useEffect, useState} from 'react'
import Tabs from "antd/es/tabs";
import Layout from "antd/es/layout/layout";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import UndoOutlined from "@ant-design/icons/UndoOutlined";
import {CreateUpdateUsersModal} from "./CreateUpdateModal"
import { SideBarComponent } from "../SideBar/SideBarComponent"
import {changeFormFieldsData} from "../../tools/changeFormFieldsData";
import {brandsSourcesModel} from "../../model/brandsSourcesModel.js";
import {BrandsSourcesList} from "./BrandSourcesList.jsx";
import moment from "moment";

import notification from "antd/es/notification";

import { observer } from 'mobx-react-lite';
import {useTranslation} from "react-i18next";



const initialFields = [
    {
        name: 'id',
        value: ''
    },
    {
        name: 'name',
        value: ''
    },
    {
        name: 'description',
        value: ''
    },
    {
        name: 'description_en',
        value: ''
    },
    {
        name: 'description_kz',
        value: ''
    },
    {
        name: 'url',
        value: ''
    },
    {
        name: 'received_at',
        value: ''
    },

]

// export const UsersContainer = () => {
export const BrandSourcesContainer = observer(() => {

    const [createUser, setCreateUser] = useState(null)
    const [updateUser, setUpdateUser] = useState(null)
    // const [changePasswordModal, setChangePasswordModal] = useState(null)
    const [userFields, setUserFields] = useState(initialFields)

    const [api, contextHolder] = notification.useNotification();


    const {t} = useTranslation()



    const DeleteNotif = (id) => {
        api.error({
            message: 'Deleted',
            description:
                `Brand source ${id} Deleted`,
        });
    };
    const CreateNotif = (username) => {
        api.success({
            message: 'Created',
            description:
                `Brand source ${username} created`,
        });
    };

    const UpdateNotif = (username) => {
        api.success({
            message: 'Updated',
            description:
                `Brand source ${username} updated`,
        });
    };


    if (brandsSourcesModel.status) {
        if (brandsSourcesModel.status === 'delete') {
            // DeleteNotif(brandsSourcesModel.current)
        }
        if (brandsSourcesModel.status === 'create') {
            // CreateNotif(brandsSourcesModel.current)
        }
        if (brandsSourcesModel.status === 'update') {
            // UpdateNotif(brandsSourcesModel.current)
        }
    }

    const onCancel = () => {
        setCreateUser(null)
        setUpdateUser(null)
        setUserFields(initialFields)
    }


    const onOpenUserModal = (type, value) => {
        if (type === 'create') {
            setCreateUser(true)
        } else if (type === 'update') {
            const payload = value
            payload.received_at = moment(value.received_at.toString())
            // payload.time = moment(value.received_at)
            setUserFields(changeFormFieldsData(userFields, payload))
            setUpdateUser(value)
        }
    }



    const onUserSubmit = (value) => {
        if (createUser) {
            const payload = value
            // payload.received_at = moment(value.date.$d)
            // delete payload.date
            // delete payload.time
            brandsSourcesModel.create(payload)
        } else if (updateUser) {
            const payload = value
            if (payload.received_at) {
                payload.received_at = moment(value.received_at).format('YYYY-MM-DD HH:mm')
            }
            brandsSourcesModel.update(payload, updateUser.id)
        }
        onCancel()
    }

    const refresh = () => {
        window.location.reload()
    }

    const sideBarMenuItems = [
        {
            title: t('Create Brand Source'),
            icon: <PlusCircleOutlined/>,
            action: () => onOpenUserModal('create'),
            // link: '/users/createUser/main',
        },
        {
            title: t('Refresh'),
            icon: <UndoOutlined/>,
            action: refresh
        }
    ]

    return (
        <>
            <SideBarComponent
                menuItems={sideBarMenuItems}
            />

            {contextHolder}
            <CreateUpdateUsersModal
                updateUser={updateUser}
                createUser={createUser}
                onCancel={onCancel}
                onSubmit={onUserSubmit}
                fields={userFields}
                history={history}
            />
            <Layout className={'contentLayout'}>
                <h2>
                    {t('Brands Sources')}
                </h2>
                <BrandsSourcesList
                    createUser={createUser}
                    setCreateUser={setCreateUser}
                    updateUser={updateUser}
                    setUpdateUser={setUpdateUser}
                    fields={userFields}
                    setFields={setUserFields}
                    onOpenModal={onOpenUserModal}
                    onCancel={onCancel}
                />
            </Layout>
        </>
    )
})
