import React from 'react'
import Form from "antd/es/form";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Select from "antd/es/select";
import DatePicker from "antd/es/date-picker";
import Button from "antd/es/button";
import Col from "antd/es/col";
import UserDeleteOutlined from "@ant-design/icons/UserOutlined";
import {usersModel} from "../../model/usersModel.js";
import { observer } from 'mobx-react-lite';
import moment from "moment/moment.js";
import {auditModel} from "../../model/auditModel.js";
import {useTranslation} from "react-i18next";


const {RangePicker} = DatePicker

const {Option} = Select

export const AuditFilter = observer((props) => {

    // const {onFilter} = props

    const currentPage = parseInt(window.location.href?.split('page=')[1]?.split('&')[0] ?? 1)
    const currentPageSize = parseInt(window.location.href?.split('limit=')[1] ?? 20)

    const {t} = useTranslation()
    const userOptions = usersModel.users.map(item => {
        return {
            value: item.name,
            label: item.name
        }
    })


    const onFilter = (value) => {
        if (value.event_date) {
            value.fromDate = moment(value?.event_date[0].toString()).format('YYYY-MM-DD HH:mm')
        }
        if (value.event_date) {
            value.toDate = moment(value?.event_date[1].toString()).format('YYYY-MM-DD HH:mm')
        }
        delete value.event_date
        auditModel.changePage(currentPage, currentPageSize, value)
    }


    return (
        <Form  name="filterAudit" onFinish={onFilter}>
        {/*<Form form={form} name="filterAudit" onFinish={v => onFilter('filter', v)}>*/}
            <Row  justify={'space-between'}>
                <Col span={3}>
                    <Form.Item
                        label={t('User')}
                        name="username"
                        labelCol={{span: 24}}
                    >
                        <Select
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            options={userOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        label={t('Event Date')}
                        name="event_date"
                        labelCol={{span: 24}}
                    >
                        <RangePicker/>
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        label={t('Object type')}
                        name="object_type"
                        labelCol={{span: 24}}
                    >
                        <Input/>
                    </Form.Item>
                </Col>



                <Col span={3}>
                    <Form.Item
                        label={t('Object name')}
                        name="object_name"
                        labelCol={{span: 24}}
                    >
                        <Input/>
                    </Form.Item>
                </Col>

                <Col span={3}>
                    <Form.Item
                        label={t('Action')}
                        name="action"
                        labelCol={{span: 24}}
                    >
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                    >
                        {t('Filter')}
                    </Button>
                </Form.Item>
                <Form.Item style={{marginLeft: 10}}>
                    <Button onClick={() => {
                        onFilter('clear')
                        form.resetFields()
                    }}>
                        {t('Clear')}
                    </Button>
                </Form.Item>
            </Row>

        </Form>
    )
})
