import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";
import cookies from "react-cookies";

export const authModel = makeAutoObservable({
    token: null,
    error: false,
    // onAuth => auth FIXME
    // LOGIN LOGOUT REFRESH =) no caps
    Login: async (username, password) => {
        // debugger
        const config = {headers: {"Content-Type": "multipart/form-data"},}
        const payload = new FormData();
        payload.append('login', username);
        payload.append('password', password);
        try {
            const token =  await instance(false).post('/auth/', payload, config).then(res => res.data)
            cookies.save('token', token, {path: '/'})
            document.location.reload()
        } catch (e) {
            authModel.error = true
            cookies.remove('token', {path: '/'})
        }
    },
    Logout: async () => {
        await instance().delete('/auth/').then(res => res.data)
        cookies.remove('token', {path: '/'})
        document.location.reload()
    },
    refreshTokenApi: async () => {
        const for_new_token = {refresh_token: cookies.load('token').refresh_token}
        // cookies.remove('token', {path: '/'})
        try {
            cookies.remove('token', {path: '/'})
            const updated_token =  await instance(false).put('/auth/', for_new_token).then(res => res.data)
            const token = {
                refresh_token: for_new_token.refresh_token,
                access_token: updated_token.access_token
            }
            cookies.save('token', token, {path: '/'})
        } catch {
            cookies.remove('token', {path: '/'})
            document.location.reload()
        }

    },

});

