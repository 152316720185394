import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";
import {hostsModel} from "./hostsModel.js";

export const hostCategoryModel = makeAutoObservable({
   data: [],
   status: false,
   current: null,
   error: null,
   currentSite: null,
   // summary


   get: async () => {
      try {
         const payload = await instance().get('/sites/?page=1&limit=20').then(res => res.data)
         hostCategoryModel.data = payload.data
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   changePage: async (page, limit, user) => {
      try {
         let userFilter = ''
         if (user != undefined && user != '') {
            userFilter = `name=${user}&`
         }
         const payload = await instance().get(`/sites/?${userFilter}page=${page}&limit=${limit}`).then(res => res.data)
         hostCategoryModel.data = payload.data
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   create: async (payload) => {
      try {
         // debugger
         payload.host_taxonomy_ids = payload.host_taxonomy_manual_ids
        const newObj =  await instance().post('/sites/', payload).then(res => res.data)
         hostCategoryModel.status = 'create'
         hostCategoryModel.current = newObj.data.name
         let page = window.location.href?.split('page=')[1]?.split('&')[0]
         let limit = window.location.href.split('limit=')[1]
         let filter = window.location.href?.split('name=')[1]?.split('&')[0]
         if (window.location.href.includes('page=')) {
            await hostCategoryModel.changePage(page, limit, filter)
         } else {
            await hostCategoryModel.get()
         }
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   delete: async (id, name) => {
      try {
         await instance().delete(`/sites/purge/${id}`).then(res => res.data)
         hostCategoryModel.status = 'delete'
         hostCategoryModel.current = name
         let page = window.location.href?.split('page=')[1]?.split('&')[0]
         let limit = window.location.href.split('limit=')[1]
         let filter = window.location.href?.split('name=')[1]?.split('&')[0]
         if (window.location.href.includes('page=')) {
            await hostCategoryModel.changePage(page, limit, filter)
         } else {
            await hostCategoryModel.get()
         }
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   update: async (payload, id) => {
      try {

         payload.host_taxonomy_ids = hostsModel.hostsSummaryArr
         await instance().put(`/sites/${id}`, payload).then(res => res.data)
         hostCategoryModel.status = 'update'
         hostCategoryModel.current = payload.name

         let page = window.location.href?.split('page=')[1]?.split('&')[0]
         let limit = window.location.href.split('limit=')[1]
         let filter = window.location.href?.split('name=')[1]?.split('&')[0]
         if (window.location.href.includes('page=')) {
            await hostCategoryModel.changePage(page, limit, filter)
         } else {
            await hostCategoryModel.get()
         }
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   getInfo: async (id) => {
      try {
         const payload = await instance().get(`/sites/${id}`).then(res => res.data)
         hostCategoryModel.currentSite = payload.data
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },

});
