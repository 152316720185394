import React, {useEffect, useState} from 'react'
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import {InputNumber} from "antd";
import Select from "antd/es/select";
import Divider from "antd/es/divider";
import Tabs from "antd/es/tabs";
// import {PermissionsTable} from "../../pages/PermissionsTable/PermissionsTable";
import {normalizeName} from "../../tools/validateNames";
// import {UniqueValueNotification} from "../../../../tools/UniqueValueNotification";
// import LimitsForm from "../../pages/LimitsForm/LimitsForm";
import './UsersGroupsModalsStyles.css';
import {hostsModel} from "../../model/hostsModel.js";
import {domainModel} from "../../model/domainModel.js";

import {classModel} from "../../model/classModel.js";
import {countriesModel} from "../../model/countriesModel.js";
import {langModel} from "../../model/langModel.js";
import {brandsModel} from "../../model/brandsModel.js";
import {sitesModel} from "../../model/sitesModel.js";
import { observer } from 'mobx-react-lite';
import {iabModel} from "../../model/iabModel.js";
import {localstorageModel} from "../../model/localstorageModel.js";
import {useTranslation} from "react-i18next";


const {TabPane} = Tabs;

const {Option} = Select


const layout = {
    layout: 'vertical'
};


export const CreateUpdateUsersModal = observer((props) => {



    const {
        updateUser,
        createUser,
        onCancel,
        onSubmit,
        fields,
    } = props



    const data_lang = hostsModel.data_lang
    const data = hostsModel.data
    const current_lang = localstorageModel.language

    const {t} = useTranslation()







    // const classSummaryDefault = classModel.classSummary
    const classSummaryDefaultArr = classModel.classSummaryArr
    const hostSummaryDefaultArr = hostsModel.hostsSummaryArr
    const hostsDictionary = hostsModel.dictionary




    const classSummaryValue = classModel.classSummaryArr.map(item => {
        return {
            label: item ? item : '-',
            value: item
        }
    })

    const hostsSummaryValue = hostsModel.hostsSummaryArr.map(item => {
        return {
            // label: item ? item : '-',
            label: hostsDictionary[current_lang][item] ? hostsDictionary[current_lang][item] : '-',
            value: item
        }
    })


    const {tabName} = 'test';



    const style = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
    }

    const width = {
        width: '100%',
    }

    const pStyle = {
        marginTop: '0',
        marginBottom: '8px',
    }

    const tabActiveKey = createUser
        ? tabName
        : updateUser;



    const hostOptions = data_lang.map(item => {
        return {
            label: hostsDictionary[current_lang][item.id] ? hostsDictionary[current_lang][item.id] : '-',
            value: item.id
        }
    })

    const domainOptions = domainModel.data.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })

    const classOptions = classModel.data.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })

    const countriesOptions = countriesModel.data.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })

    const languagesOptions = langModel.data.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })


    const brandsOptions = brandsModel.data.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })

    const siteSourceOptions = sitesModel.data.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })

    const classModelOptions = classModel?.currentModel?.map(item => {
        return {
            label: classModel.dictionary[item] ? classModel.dictionary[item] : item,
            value: item
        }
    })


    const classModelDefault = classModelOptions?.map(item => {
        return item.label
    })

// FIXME
    const hostModelOptions = hostsModel?.currentModel?.map(item => {
        return {
            label: hostsDictionary[current_lang][item] ? hostsDictionary[current_lang][item] : '-',
            value: item
        }
    })



    // const hostModelDefault = hostModelOptions?.map(item => {
    //     return hostsDictionary[current_lang][item.id] ? hostsDictionary[current_lang][item.id] : '-'
    // })

    const hostModelDefault = hostModelOptions













    const tabs = [
        {
            label: t('Main'),
            key: '1',
            children: (
                <>
                <Row justify={'space-between'}>
                    <Col span={7}>
                        <Form.Item
                            label={t("Name")}
                            name="name"
                            rules={[
                                {required: true, message: 'Enter name!'},
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item
                            label={t("Country")}
                            name="country_id"

                        >
                            <Select
                                options={countriesOptions}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item
                            label={t("Domain zone")}
                            name="domain_zone_id"

                        >
                            <Select
                                options={domainOptions}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'space-between'}>
                    <Col span={7}>
                        <Form.Item
                            label={t("Language")}
                            name="language_ids"
                        >
                            <Select
                                mode="multiple"
                                options={languagesOptions}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item
                            label={t("City geolocation")}
                            name="city_geolocation"

                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={7}>
                        <Form.Item
                            label={t("Ip addresses")}
                            name="ip_addresses"

                        >
                            <Select
                                mode="tags"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                </>
            ),
        },
        {
            label: t('Advanced'),
            key: '2',
            children: (
                <>
                <Row justify={"space-between"}>
                    <Col span={5}>
                        <Form.Item
                            label={t("Tags")}
                            name="tags"

                        >
                            <Select
                                mode="tags"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item
                            label={t("Brand")}
                            name="brand_ids"

                        >
                            <Select
                                mode="multiple"
                                options={brandsOptions}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item
                            label={t("Site Source")}
                            name="site_source_id"

                        >
                            <Select
                                options={siteSourceOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            label={t("Rating class level")}
                            name="rating_class_level"

                        >
                            <InputNumber stlye={width}/>
                        </Form.Item>
                    </Col>
                </Row>
                    <Row justify={"space-between"}>
                        <Col span={5}>
                            <Form.Item
                                label={t("Class manual")}
                                name="class_manual_ids"

                            >
                                <Select
                                    mode="multiple"
                                    options={classOptions}
                                    onChange={classModel.addManual}
                                />
                            </Form.Item>
                        </Col>

                        {
                            updateUser?.id ?
                                <Col span={5}>
                                    <Form.Item
                                        label={t("Сlass exclude")}
                                        name="class_exclude_ids"
                                    >
                                        <Select
                                            mode="tags"
                                            options={classModelOptions}
                                            onChange={classModel.addExclude}
                                        />
                                    </Form.Item>
                                </Col>
                                :
                                ''
                        }

                        {
                            updateUser?.id ?
                                <Col span={5}>
                                    <div>
                                        <p style={pStyle}>
                                            {t('Class Model')}
                                        </p>
                                        <Select
                                            mode="tags"
                                            defaultValue={classModelDefault}
                                            disabled={true}
                                            style={width}
                                        />
                                    </div>
                                </Col>
                                :
                                ''
                        }

                        {
                            updateUser?.id ?
                                <Col span={5}>
                                    <div>
                                        <p style={pStyle}>
                                            {t('Class Summary')}
                                        </p>
                                        <Select
                                            mode="tags"
                                            disabled={true}
                                            value={classSummaryValue}
                                            style={width}
                                        />
                                    </div>
                                </Col>
                                :
                                ''
                        }

                    </Row>
                    <Row justify={"space-between"}>
                        <Col span={24}>
                            <Form.Item
                                label={t("Host taxonomy")}
                                name="host_taxonomy_manual_ids"
                            >
                                <Select
                                    mode="multiple"
                                    options={hostOptions}
                                    onChange={hostsModel.addManual}
                                    filterOption={false}
                                />
                            </Form.Item>
                        </Col>
                        {
                            updateUser?.id ?
                                <Col span={24}>
                                    <Form.Item
                                        label={t("Host Exclude")}
                                        name="host_taxonomy_exclude_ids"
                                    >
                                        <Select
                                            mode="multiple"
                                            onChange={hostsModel.addExclude}
                                            options={hostModelOptions}
                                        />
                                    </Form.Item>
                                </Col>
                                :
                                ''
                        }

                        {
                            updateUser?.id ?
                                <Col span={24}>
                                    <div>
                                        <p style={pStyle}>
                                            {t('Host Model')}
                                        </p>
                                        <Select
                                            mode="tags"
                                            defaultValue={hostModelDefault}
                                            disabled={true}
                                            style={width}
                                        />
                                    </div>
                                </Col>
                                :
                                ''
                        }
                        {
                            updateUser?.id ?
                                <Col span={24}>
                                    <div>
                                        <p style={pStyle}>
                                            {t('Host Summary')}
                                        </p>
                                        <Select
                                            mode="tags"
                                            disabled={true}
                                            value={hostsSummaryValue}
                                            style={width}
                                        />
                                    </div>
                                </Col>
                                :
                                ''
                        }
                    </Row>
                </>
            ),
        },
    ]




    return (
        <Modal title={updateUser ? `${t('Update Host')} -  ${updateUser?.name}` : t("Create Host")}
               wrapClassName='createUpdateUsersModal'
               visible={updateUser || createUser}
               onCancel={onCancel}
               style={{top: 10}}
               destroyOnClose={true}
               footer={[
                   <div key={1} style={{padding: '0 8px'}}>
                       <Button onClick={onCancel}>
                           {t('Cancel')}
                       </Button>
                       <Button form="userForm" type={"primary"} htmlType="submit">
                           {t('Save')}
                       </Button>
                   </div>
               ]}
               width={1000}>
                    <Form
                        id="userForm"
                        {...layout}
                        name="userForm"
                        // fields={fields}
                        fields={updateUser?.id ? fields : ''}
                        style={style}
                        onFinish={onSubmit}
                    >

                        <Tabs
                            items={tabs}
                        />

                    </Form>
        </Modal>



    )
})
