import React, {useState} from 'react'
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Divider from "antd/es/divider";
import Select from "antd/es/select";

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 14},
};


export const DeleteModalComponent = (props) => {

    const {
        chooseParentOptions,
        // FIXME
        DeletionOptions,
        selectedDeletionOption,
        onCancel,
        visible,
        handleDeletionOptionChange,
        onDeleteTerm,
        currentIabForDelete
    } = props



    return (
        <>
            <Modal
                title="Delete IAB"
                visible={visible}
                onCancel={onCancel}
                destroyOnClose={true}
                footer={[
                    <div key={1}>
                        <Button onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button form="deleteIab" type={"primary"} htmlType="submit">
                            Delete
                        </Button>
                    </div>
                ]}

            >
                <Divider/>
                <p>What to do with child elements</p>
                <Form
                    id="deleteIab"
                    name="deleteIab"
                    onFinish={onDeleteTerm}
                    // layout={'inline'}
                    layout={"horizontal"}
                >
                <Form.Item
                name='action'
                >
                    <Select
                        defaultValue={DeletionOptions[0]}
                        style={{ width: 300 }}
                        options={DeletionOptions}
                        onChange={handleDeletionOptionChange}
                    />
                </Form.Item>
                {selectedDeletionOption === 'change_parent' && (
                    <Form.Item
                    name='parent_id'
                    label='Chose Parent'
                    rules={[
                        {required: true, message: 'Select parent!'},
                    ]}
                    >
                        {/*<p>Chose Parent</p>*/}
                        <Select
                            // defaultValue={chooseParentOptions[0]}
                            style={{ width: 300 }}
                            options={chooseParentOptions.filter(item => item.value !== currentIabForDelete.id)}
                        />
                    </Form.Item>
                )}
                </Form>
            </Modal>
        </>
    );


}