import React, {useEffect} from 'react'
import Button from "antd/es/button";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Table from "antd/es/table";
import Badge from "antd/es/badge";
import {COLORS} from "../../constants/colors";
// import {ChangePasswordModal} from "./ChangePasswordModal";
import Popconfirm from "antd/es/popconfirm";
import {Content} from "antd/es/layout/layout";
import Tooltip from "antd/es/tooltip";
import {StopFilled, ZhihuSquareFilled} from "@ant-design/icons";
import CheckCircleFilled from "@ant-design/icons/CheckCircleFilled";
import {Link} from "react-router-dom";
import Spin from "antd/es/spin";
import {usersModel} from "../../model/usersModel.js";
import {classModel} from "../../model/classModel.js";
import {autorun} from "mobx";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { observer } from 'mobx-react-lite';
import {langModel} from "../../model/langModel.js";
import {profileModel} from "../../model/profleModel.js";
import {localstorageModel} from "../../model/localstorageModel.js";
import {useTranslation} from "react-i18next";


export const LangList = observer((props) => {
    const {
        onOpenModal,
    } = props

    const deleteUser = (id, name) => {
        langModel.delete(id, name)
    }
    const {t} = useTranslation()

    const current_lang = localstorageModel.language

    const columns = [
        {
            title: 'ID',
            width: '15%',
            dataIndex: 'id',
        },
        {
            title: t('Name'),
            width: '15%',
            dataIndex: 'name',
            render: (name, item) => current_lang === 'ru' ? item.name :
                item[`name_${current_lang}`] ? item[`name_${current_lang}`] : '-'
        },
        {
            title: t('Short name'),
            dataIndex: 'short_name',
            width: '20%',
        },
        {
            title: t('Actions'),
            align: 'center',
            width: '10%',
            className: 'table-operations',
            render: user =>
                <Spin spinning={user.status === 'processing'}>
                    <Button.Group style={{display: 'flex', flexWrap: 'wrap', width: '46px', justifyContent: 'center', margin: '0 auto'}}>
                            <Tooltip placement="topLeft" title={t('Update Language')}>
                                <Link >
                                    <Button type="default" onClick={() => onOpenModal('update', user)}>
                                        <EditOutlined/>
                                    </Button>
                                </Link>
                            </Tooltip>
                        <Popconfirm
                            title={t(`Are you sure to delete this Language?`)}
                            onConfirm={() => deleteUser(user.id, user.name)}
                            okText={t("Yes")}
                            cancelText={t("No")}
                        >
                            <Tooltip placement="topLeft" title={t('Delete this Language')}>
                                <Button type="default" style={{marginLeft: 0}}>
                                    <DeleteOutlined />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </Button.Group>
                </Spin>
        }
    ];

    useEffect(
        // FIXME useEffect GO TO MODEL!! OR CONTAINER
        () =>
            autorun(() => {
                console.log('useEffect ReRender::')
                langModel.get()
                profileModel.get()
            }),
        [],
    )

    const data = langModel.data
    // const data = classModel.classes.map(item => {
    //     return {
    //         created_at: item.created_at,
    //         created_by: item.created_by,
    //         description: item.description,
    //         id: item.id,
    //         name: item.name,
    //         updated_at: item.updated_at,
    //         updated_by: item.updated_by
    //     }
    // })



    return (
        <Content>
            <Table
                bordered
                id='usersListTable'
                size={'small'}
                dataSource={data}
                columns={columns}
                pagination={{
                    position: ['bottomCenter'],
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    pageSizeOptions: [20, 50, 100, 200]
                }}
            />

        </Content>
    )
});

