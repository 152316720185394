import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";

export const sitesModel = makeAutoObservable({
   data: [],
   status: false,
   current: null,
   error: null,
   currentSite: null,

   // FIXME! ADD API URL

   get: async () => {
      try {
         const payload = await instance().get('/site_sources/').then(res => res.data)
         sitesModel.data = payload.data
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   create: async (payload) => {
      try {
        const newObj =  await instance().post('/site_sources/', payload).then(res => res.data)
         sitesModel.status = 'create'
         sitesModel.current = newObj.data.name
         await sitesModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   delete: async (id, name) => {
      try {
         await instance().delete(`/site_sources/purge/${id}`).then(res => res.data)
         sitesModel.status = 'delete'
         sitesModel.current = name
         await sitesModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   update: async (payload, id) => {
      try {
         await instance().put(`/site_sources/${id}`, payload).then(res => res.data)
         sitesModel.status = 'update'
         sitesModel.current = payload.name
         await sitesModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   getInfo: async (id) => {
      try {
         const payload = await instance().get(`/site_sources/${id}`).then(res => res.data)
         sitesModel.currentSite = payload.data
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },

});
