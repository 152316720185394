import React, {useEffect} from 'react'
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Divider from "antd/es/divider";
import Tabs from "antd/es/tabs";
// import {PermissionsTable} from "../../pages/PermissionsTable/PermissionsTable";
import {normalizeName} from "../../tools/validateNames";
// import {UniqueValueNotification} from "../../../../tools/UniqueValueNotification";
// import LimitsForm from "../../pages/LimitsForm/LimitsForm";
import './UsersGroupsModalsStyles.css';
import {classModel} from "../../model/classModel.js";
import {iabModel} from "../../model/iabModel.js";
import {hostsModel} from "../../model/hostsModel.js";
import {localstorageModel} from "../../model/localstorageModel.js";
import {useTranslation} from "react-i18next";

const {TabPane} = Tabs;

const {Option} = Select


const layout = {
    layout: 'vertical'
};

const rawHostCatIds = hostsModel.data.map(item => item.id)



export const CreateUpdateUsersModal = (props) => {

    const {
        updateUser,
        createUser,
        onCancel,
        onSubmit,
        fields,
        users,
        history,
        pathAction,
    } = props


    const {tabName} = 'test';

    const data_lang = hostsModel.data_lang
    const data = hostsModel.data

    const current_lang = localstorageModel.language
    const hostsDictionary = hostsModel.dictionary

    const {t} = useTranslation()


    const checkName = (_, value) => {
        const validName = new RegExp('^[_a-z][_a-z0-9]+$')
        if (value && !validName.test(value)) {
            return Promise.reject(new Error('Login could contain only letters, numbers, - and _'));
        }
        if (value?.length <= 3) {
            return Promise.reject(new Error('Enter minimum 3 letters!'));
        }
        if (value && users.findIndex(item => item.username === value) !== -1) {
            UniqueValueNotification('login')
            return Promise.reject(new Error('Enter unique login!'));
        }
        return Promise.resolve()
    }


    const onChangeTab = (tabName) => {
        if (updateUser) {
            history.push(`/users/users/${updateUser.id}/updateUser`)
        }
        if (createUser) {
            history.push(`/users/${pathAction}`)
        }
    };

    const style = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
    }

    const width = {
        width: '100%'
    }

    const classOptions = classModel.data.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })


    let iabData = []


    iabModel.data.map(item => {
        if (!rawHostCatIds.includes(item.iab_id)) {
            iabData.push(item)
        }
    })



    const iabOptions = iabData.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })
    const parentsOptions = data_lang.filter(item => item.tier_level !== 4).map(item => {
        return {
            // label: item['name_'+current_lang],
            label: hostsDictionary[current_lang][item.id] ? hostsDictionary[current_lang][item.id] : '-',
            value: item.id
        }
    })






    const tabActiveKey = createUser
        ? tabName
        : updateUser;


    return (
        <Modal title={updateUser ? `${t('Update Host Taxonomy')} ` : t("Create Host Taxonomy")}
               wrapClassName='createUpdateUsersModal'
               visible={updateUser || createUser}
               onCancel={onCancel}
               style={{top: 10}}
               destroyOnClose={true}
               footer={[
                   <div key={1} style={{padding: '0 8px'}}>
                       <Button onClick={onCancel}>
                           {t('Cancel')}
                       </Button>
                       <Button form="userForm" type={"primary"} htmlType="submit">
                           {t('Save')}
                       </Button>
                   </div>
               ]}
               width={600}>
            <p>
            {
                updateUser ? updateUser['name_'+current_lang+'_lvl'].split(updateUser['name_'+current_lang])[0] : ''
            }
            </p>


            {/*<p>{updateUser.name_ru_lvl.split(updateUser.name_ru)[0]}${updateUser.name_ru_lvl.split(updateUser.name_ru)[0]}</p>*/}
                    <Form
                        id="userForm"
                        {...layout}
                        name="userForm"
                        fields={fields}
                        style={style}
                        onFinish={onSubmit}
                    >
                        <Row justify={'space-between'} style={{flexDirection: 'column'}} >
                            <Col span={24}>
                                <Form.Item
                                    label={t("Name RU")}
                                    name="name_ru"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label={t("Name EN")}
                                    name="name_en"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label={t("Name KZ")}
                                    name="name_kz"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label={t("Description")}
                                    name="description"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label={t("Description EN")}
                                    name="description_en"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label={t("Description KZ")}
                                    name="description_kz"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={t("Parent")}
                                    name="parent_id"
                                >
                                    <Select
                                        options={parentsOptions}
                                    />
                                </Form.Item>
                            </Col>


                        </Row>

                    </Form>
        </Modal>



    )
}
