import React from 'react'
import Text from "antd/es/typography/Text";
import Input from "antd/es/input";
import Switch from "antd/es/switch";
import Select from "antd/es/select";
import Button from "antd/es/button";
import Popconfirm from "antd/es/popconfirm";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import {RulesComponent} from "../../components/RulesComponent.jsx";
// import {RulesComponent} from "../../../../components/RulesComponent/RulesComponent";
import TreeSelect from "antd/es/tree-select";
import {RelationsComponent} from "./RelationsComponent.jsx";
// import {RelationsComponent} from "../../pages/Terms/components/RelationsComponent/RelationsComponent";
import Row from "antd/es/row";
import Col from "antd/es/col";
import {Tabs} from "antd";
import {ClassTemplate} from "./ClassTemplate.jsx";


const {Option} = Select
const { TabPane } = Tabs;

const layout = {
    labelCol: {span: 3},
    wrapperCol: {span: 21},
};

export const ClassModal = (props) => {

    const {
        dependsRules,
        createTermModal,
        updateTermModal,
        onDeleteRules,
        constOptions,
        onChangeRules,
        onChangeDependsRules,
        onCancel,
        fields,
        onSubmit,
        propagateForm,
        propagateRules,
        setPropagateRules,
        setDependsRules,
        applyForm,
        applyRules,
        setApplyRules,
        isGroup,
        setIsGroup,
        relationForm,
        relations,
        setRelations,
        setUiRules,
        uiForm,
        uiRules,
        relatedTerms,
        termTypes,
        termTypeParams,
        termTypesForm,
        setTypeParams,
        currentTermType,
        relationTypes,
    } = props


    const columns = (ruleType) => [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: true,
            render: (text) => <Text>{text}</Text>,
            form: {
                style: {width: 180, marginLeft: 10},
                render: <Input/>
            }

        },
        {
            title: 'Include',
            dataIndex: 'include',
            key: 'include',
            align: 'center',
            render: (include, rule) => <Switch checked={include}
                                               onChange={v => onChangeRules(ruleType, rule.key, 'include', v)}/>,
            form: {
                style: {width: 100, marginBottom: '15px'},
                valuePropName: "checked",
                initialValue: true,
                render: <Switch onChange={v => onChangeDependsRules(ruleType, 'include', v)}/>
            }
        },
        {
            title: 'Exclude',
            dataIndex: 'exclude',
            key: 'exclude',
            align: 'center',
            render: (exclude, rule) => <Switch checked={exclude}
                                               onChange={v => onChangeRules(ruleType, rule.key, 'exclude', v)}/>,

            form: {
                style: {width: 100, marginBottom: '15px'},
                valuePropName: "checked",
                render: <Switch onChange={v => onChangeDependsRules(ruleType, 'exclude', v)}/>
            }
        },
        {
            title: 'Dataset',
            dataIndex: 'dataset',
            key: 'dataset',
            editable: true,
            render: (text, rule) => <Text disabled={rule.level === 'source' || rule.level === 'dataset'}>{text}</Text>,

            form: {
                style: {width: 191},
                render: <Input
                    disabled={dependsRules[ruleType].level === 'source' || dependsRules[ruleType].level === 'dataset'}/>
            }
        },
        {
            title: 'Parent',
            dataIndex: 'parent',
            key: 'parent',
            editable: true,
            render: (text) => <Text>{text}</Text>,

            form: {
                style: {width: 180},
                render: <Input disabled={dependsRules[ruleType].level === 'source'}/>
            }

        },
        {

            title: 'Required',
            dataIndex: 'required',
            key: 'required',
            removed: ruleType !== 'ui',
            align: 'center',
            render: (include, rule) => <Switch checked={include}
                                               onChange={v => onChangeRules(ruleType, rule.key, 'required', v)}/>,
            form: {
                style: {width: 180, marginBottom: '15px'},
                valuePropName: "checked",
                render: <Switch onChange={v => onChangeDependsRules(ruleType, 'required', v)}/>
            }
        },
        {
            key: 'operations',
            align: 'center',
            operations: true,
            width: 50,
            render: (rule) => (
                <Button.Group>
                    <Popconfirm
                        title={`Are you sure to delete this ${ruleType} rule?`}
                        onConfirm={() => onDeleteRules(ruleType, rule.key)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger>
                            <DeleteOutlined/>
                        </Button>
                    </Popconfirm>
                </Button.Group>
            ),
        }
    ];

    return (
        <Modal title={createTermModal ? "Create Term" : `Update Term - ${updateTermModal?.name}`}
               visible={createTermModal || updateTermModal}
               onCancel={onCancel}
               style={{top: 10}}
               destroyOnClose={true}
               width={1150}
               footer={[<div key={1}>
                   <Button onClick={onCancel}>
                       Cancel
                   </Button>
                   <Button form="termForm" htmlType="submit" type={"primary"}>
                       Save
                   </Button>
               </div>]}>

            <Tabs defaultActiveKey="info">
                <TabPane tab="Info" key="info">
                    <Form
                        id="termForm"
                        {...layout}
                        name="termForm"
                        fields={fields}
                        onFinish={onSubmit}
                    >
                        <Row justify="center">
                            <Col span={10} style={{marginRight: 10, height: 70}}>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    className={'labelWithoutPadding'}
                                    labelCol={{span: 24}}
                                    rules={[
                                        {required: true, message: 'Enter first name!'}
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    label="Short Description"
                                    name="short_description"
                                    className={'labelWithoutPadding'}
                                    labelCol={{span: 24}}
                                    rules={[{required: true, message: 'Enter short description!'}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={23}>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    className={'labelWithoutPadding'}
                                    labelCol={{span: 24}}
                                    style={{marginLeft: 65}}
                                >
                                    <Input.TextArea
                                        rows={2}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginLeft: 90}}>
                            <Col span={12}>
                                <Form.Item
                                    label="Type"
                                    name="term_type_id"
                                    className={'labelWithoutPadding'}
                                    labelCol={{span: 24}}
                                    initialValue={null}
                                    rules={[{required: true, message: 'Select type'}]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a type"
                                        optionFilterProp="children"
                                        onChange={(value) => setTypeParams(value)}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {(termTypes || [])?.map(type => <Option key={type.id} value={type.id}>{type.name}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {termTypeParams.length > 0 &&
                                <Col style={{marginTop: 20}} span={21}>
                                    <ClassTemplate
                                        termTypeParams={termTypeParams}
                                        termTypesForm={termTypesForm}
                                        currentTermType={currentTermType}
                                    />
                                </Col>
                            }
                        </Row>
                        <Row style={{display: 'flex', marginTop: 30, marginLeft: 92}}>
                            <Form.Item
                                label="Is group"
                                name="is_group"
                                className={'labelWithoutPadding'}
                                valuePropName="checked"
                                labelCol={{span: 24}}
                                style={{width: 120}}
                            >
                                <Switch
                                    onChange={setIsGroup}
                                    style={{marginRight: "57px"}}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Group"
                                name="group_id"
                                className={'labelWithoutPadding'}
                                labelCol={{span: 24}}
                                style={{width: 300, marginLeft: 10}}
                            >
                                <Select
                                    disabled={isGroup}
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {/*{constOptions?.terms?.terms?.filter(term => term.is_group)?.map(term => <Option*/}
                                    {/*    key={`term-${term.id}`} value={term.id}>{term.name}</Option>)}*/}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Categories"
                                name="category_ids"
                                className={'labelWithoutPadding'}
                                labelCol={{span: 24}}
                                style={{width: 435}}
                            >
                                <TreeSelect
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    // treeData={constOptions?.terms?.categories}
                                    treeDefaultExpandAll
                                    multiple
                                    fieldnames={{label: 'name', value: 'id', children: 'children'}}
                                />
                            </Form.Item>
                        </Row>
                        <Row style={{display: 'flex', marginLeft: 92}}>
                            <Col span={10}>
                                <Form.Item
                                    label="Responsibility on"
                                    name="responsibility_on"
                                    className={'labelWithoutPadding'}
                                    labelCol={{span: 24}}
                                >
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {/*{constOptions.users?.map(user => <Option*/}
                                        {/*    key={`user-${user.id}`} value={user.id}>{user.full_name}</Option>)}*/}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    label="Owner"
                                    name="owner"
                                    className={'labelWithoutPadding'}
                                    style={{width: 432, marginLeft: 12}}
                                    labelCol={{span: 24}}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
                <TabPane tab="Relations" key="relations">
                    <RelationsComponent
                        types={relationTypes}
                        relationForm={relationForm}
                        relations={relations}
                        setRelations={setRelations}
                        relatedTerms={relatedTerms}
                    />
                </TabPane>
                <TabPane tab="Propagate Rules" key="propagate">
                    {/*Propagate Rules block*/}
                    <RulesComponent
                        ruleType={'propagate'}
                        form={propagateForm}
                        columns={columns}
                        rules={propagateRules}
                        setRules={setPropagateRules}
                        setDependsRules={setDependsRules}
                    />
                </TabPane>
                <TabPane tab="Apply Rules" key="apply">
                    {/*Apply Rules block*/}
                    <RulesComponent
                        ruleType={'apply'}
                        form={applyForm}
                        columns={columns}
                        rules={applyRules}
                        setRules={setApplyRules}
                        setDependsRules={setDependsRules}
                    />
                </TabPane>
                <TabPane tab="UI Rules" key="ui">
                    {/*UI Rules block*/}
                    <RulesComponent
                        ruleType={'ui'}
                        form={uiForm}
                        columns={columns}
                        rules={uiRules}
                        setRules={setUiRules}
                        setDependsRules={setDependsRules}
                    />
                </TabPane>

            </Tabs>
        </Modal>
    )
}
