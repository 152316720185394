import React from 'react'
import Row from 'antd/es/row'
import Form from 'antd/es/form'
import Input from 'antd/es/input'
import Button from 'antd/es/button'
import Card from 'antd/es/card'
import {authModel} from "../model/auth.js";
// import { observer } from 'mobx-react-lite';
import {Observer} from "mobx-react";

import notification from "antd/es/notification";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const tailLayout = {
    wrapperCol: {offset: 11},
};

const cardStyle = {
    padding: '45px 50px 0 10px',
    boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)"
}


// export const Auth = observer(() => {
export const Auth = () => {
    const onFinish = ({username, password}) => {
        authModel.Login(username, password)
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotification = () => {
        api.error({
            message: 'Auth Error',
            description:
                'Check login or password',
        });
    };


    if (authModel.error == true) {
        openNotification()
    }
    return (
        <Observer>
            {
                () => <Row align={'middle'} justify={'space-around'} style={{height: '100vh'}}>
                    <Card style={{...cardStyle}}>
                        {contextHolder}
                        <Form
                            {...layout}
                            initialValues={{remember: true}}
                            onFinish={onFinish}
                        >

                            <Form.Item
                                label="Login"
                                name="username"
                                rules={[{required: true, message: 'Enter login'}]}
                            >
                                <Input
                                    status={authModel.error == true ? 'error' : ''}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{required: true, message: 'Enter password'}]}
                            >
                                <Input.Password
                                    status={authModel.error == true ? 'error' : ''}
                                />
                            </Form.Item>
                            <Form.Item {...tailLayout} >
                                <Button type={'primary'}
                                        htmlType="submit"
                                >
                                    Login
                                </Button>
                            </Form.Item>

                        </Form>

                    </Card>

                </Row>
            }
        </Observer>



    )
};
// });


