import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";

export const brandsSourcesModel = makeAutoObservable({
   data: [],
   status: false,
   current: null,
   error: null,

   get: async () => {
      try {
         const payload = await instance().get('/brand_sources/').then(res => res.data)
         brandsSourcesModel.data = payload.data
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   create: async (payload) => {
      try {
        const newObj =  await instance().post('/brand_sources/', payload).then(res => res.data)
         brandsSourcesModel.status = 'create'
         brandsSourcesModel.current = newObj.data.name
         await brandsSourcesModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   delete: async (id, name) => {
      try {
         await instance().delete(`/brand_sources/purge/${id}`).then(res => res.data)
         brandsSourcesModel.status = 'delete'
         brandsSourcesModel.current = name
         await brandsSourcesModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   update: async (payload, id) => {
      try {
         await instance().put(`/brand_sources/${id}`, payload).then(res => res.data)
         brandsSourcesModel.status = 'update'
         brandsSourcesModel.current = payload.name
         await brandsSourcesModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },

});
