import React, {useEffect} from 'react'
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Tabs from "antd/es/tabs";
import './UsersGroupsModalsStyles.css';
import {InputNumber} from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import {brandsSourcesModel} from "../../model/brandsSourcesModel.js";
import {hostsModel} from "../../model/hostsModel.js";
import {iabModel} from "../../model/iabModel.js";
import {localstorageModel} from "../../model/localstorageModel.js";
import {useTranslation} from "react-i18next";

const {TabPane} = Tabs;

const {Option} = Select


const layout = {
    layout: 'vertical'
};





export const CreateUpdateUsersModal = (props) => {

    const {
        updateUser,
        createUser,
        onCancel,
        onSubmit,
        fields,
        users,
        history,
        pathAction,
        сountries,
        onChangeCheck,
        checked

    } = props

    const current_lang = localstorageModel.language
    const hostsDictionary = hostsModel.dictionary
    const data_lang = hostsModel.data_lang

    const {t} = useTranslation()


    const {tabName} = 'test';

    const checkName = (_, value) => {
        const validName = new RegExp('^[_a-z][_a-z0-9]+$')
        if (value && !validName.test(value)) {
            return Promise.reject(new Error('Login could contain only letters, numbers, - and _'));
        }
        if (value?.length <= 3) {
            return Promise.reject(new Error('Enter minimum 3 letters!'));
        }
        if (value && users.findIndex(item => item.username === value) !== -1) {
            UniqueValueNotification('login')
            return Promise.reject(new Error('Enter unique login!'));
        }
        return Promise.resolve()
    }


    const onChangeTab = (tabName) => {
        if (updateUser) {
            history.push(`/users/users/${updateUser.id}/updateUser`)
        }
        if (createUser) {
            history.push(`/users/${pathAction}`)
        }
    };

    const style = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
    }


    const сountriesOptions = props.countries.map(item => {
        return {
            value: item.id,
            label: item.name,
            key: item.id
        }
    })


    const hostOptions = data_lang.map(item => {
        return {
            label: hostsDictionary[current_lang][item.id] ? hostsDictionary[current_lang][item.id] : '-',
            value: item.id,
            key: item.id
        }
    })




    const brandSourcesOptions = brandsSourcesModel.data.map(item => {
        return {
            value: item.id,
            label: item.name,
            key: item.id
        }
    })

    const width = {
        width: '100%'
    }


    const tabActiveKey = createUser
        ? tabName
        : updateUser;


    return (
        <Modal title={updateUser ? `${t('Update Brand')} - ${updateUser?.name}` : t("Create Brand")}
               wrapClassName='createUpdateUsersModal'
               visible={updateUser || createUser}
               onCancel={onCancel}
               style={{top: 10}}
               destroyOnClose={true}
               footer={[
                   <div key={1} style={{padding: '0 8px'}}>
                       <Button onClick={onCancel}>
                           {t('Cancel')}
                       </Button>
                       <Button form="userForm" type={"primary"} htmlType="submit">
                           {t('Save')}
                       </Button>
                   </div>
               ]}
               width={1000}>
                    <Form
                        id="userForm"
                        {...layout}
                        name="userForm"
                        fields={fields}
                        style={style}
                        onFinish={onSubmit}
                    >
                        <Row justify={'space-between'}>
                            <Col span={7}>
                                <Form.Item
                                    label={t("Name")}
                                    name="name"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                        // {
                                        //     pattern: '^[а-яА-ЯёЁa-zA-Z]+$',
                                        //     message: 'Please enter a valid only A-z',
                                        // }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label={t("Country")}
                                    name="country_id"
                                >
                                    {/*FIXME WAIT*/}
                                    <Select
                                        options={сountriesOptions}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={7}>

                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label={t("Host Taxonomy")}
                                    name="host_taxonomy_ids"
                                >
                                    <Select
                                        mode="multiple"
                                        options={hostOptions}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row justify={'space-between'}>
                            <Col span={7}>
                                <Form.Item
                                    label={t("Site")}
                                    name="site_url"
                                    // rules={[
                                    //     {required: true, message: 'Enter name!'},
                                    // ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label={t("Products")}
                                    name="products"
                                    // rules={[
                                    //     {required: true, message: 'Enter name!'},
                                    // ]}
                                >
                                    {/*FIXME WAIT*/}
                                    <Select
                                        mode="tags"
                                        // options={сountriesOptions}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={7}>
                                <Form.Item
                                    label={t("Brand created year")}
                                    name="creation_year"
                                    // rules={[
                                    //     {required: true, message: 'Enter name!'},
                                    // ]}
                                >
                                    <InputNumber
                                        style={width}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify={'space-between'}>
                            <Col span={7}>
                                <Form.Item
                                    label={t("Brand source id")}
                                    name="brand_source_id"
                                    // rules={[
                                    //     {required: true, message: 'Enter name!'},
                                    // ]}
                                >
                                    {/*FIXME WAIT*/}
                                    <Select
                                        options={brandSourcesOptions}
                                    />
                                </Form.Item>
                            </Col>


                            <Col span={7}>
                                <Form.Item
                                    label={t("Active")}
                                    name="is_active"
                                >
                                    <Checkbox
                                        onChange={() => onChangeCheck(event.target.checked)}
                                        checked={checked}
                                        // defaultChecked={true}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>


                        <Row justify={'space-between'}>
                            <Col span={7}>
                                <Form.Item
                                    label={t("Description")}
                                    name="description"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label={t("Description EN")}
                                    name="description_en"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label={t("Description KZ")}
                                    name="description_kz"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
        </Modal>



    )
}
