import React from 'react'
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Input from "antd/es/input";
import {interestModel} from "../../model/interestModel";
import Select from "antd/es/select/index.js";
import {useTranslation} from "react-i18next";

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 14},
};


export const SectionModal = (props) => {

    const {createSectionModal, updateSectionModal, onCancel, updateSectionFields, onSubmitSectionModal} = props

    const {t} = useTranslation()

    const parentsOptions = updateSectionModal == false ? interestModel.data.map(el => {
            return {
                label: el.name,
                value: el.id
            }
        })
        :
        interestModel.data.filter(item => item.id != updateSectionModal.id).map(el => {
            return {
                label: el.name,
                value: el.id
            }
        })

    console.log('updateSectionFields', updateSectionFields)




    return (
        <Modal title={updateSectionModal ? t("Update Interest") : t("Create Interest")}
               visible={updateSectionModal || createSectionModal}
               onCancel={onCancel}
               destroyOnClose={true}
               footer={[
                   <div key={1}>
                       <Button onClick={onCancel}>
                           {t('Cancel')}
                       </Button>
                       <Button form="sectionForm" type={"primary"} htmlType="submit">
                           {t('Save')}
                       </Button>
                   </div>
               ]}
               width={600}>
            <Form
                id="sectionForm"
                {...layout}
                name="sectionForm"
                fields={updateSectionFields}
                onFinish={onSubmitSectionModal}
            >

                <Form.Item
                    label={t("Name RU")}
                    name="name"
                    rules={[
                        {required: true, message: 'Enter name!'},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={t("Name EN")}
                    name="name_en"
                    rules={[
                        {required: true, message: 'Enter name!'},
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={t("Name KZ")}
                    name="name_kz"
                    rules={[
                        {required: true, message: 'Enter name!'},
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label={t("Description")}
                    name="description"
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>

                <Form.Item
                    label={t("Description EN")}
                    name="description_en"
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>

                <Form.Item
                    label={t("Description KZ")}
                    name="description_kz"
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>


                <Form.Item
                    label={t("Parent")}
                    name="parent_id"
                >
                    <Select
                        options={parentsOptions}
                    />
                </Form.Item>

                <Form.Item
                    label={t("Rules")}
                    name="rules"
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>


            </Form>
        </Modal>
    )
}
