import React, {useEffect, useState} from 'react'
import Tabs from "antd/es/tabs";
import {AuditList} from "./AuditList";
import Form from "antd/es/form";
// import {objectToUrl, urlToObject} from "../../tools/urlToObject";
// import {HelpModal} from "../../components/HelpModal/HelpModal";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import {SideBarComponent} from "../SideBar/SideBarComponent.jsx";
import FileOutlined from "@ant-design/icons/FileOutlined";
import UndoOutlined from "@ant-design/icons/UndoOutlined";
import Layout from "antd/es/layout/layout";
import {AuditFilter} from "./AuditFilter";
import {autorun, observable} from "mobx";
import {usersModel} from "../../model/usersModel.js";
import {profileModel} from "../../model/profleModel.js";
import { observer } from 'mobx-react-lite';
import moment from "moment";
import {auditModel} from "../../model/auditModel.js";
import {useTranslation} from "react-i18next";

const {TabPane} = Tabs;

export const AuditContainer = observer((props) => {

    const {dispatch, history} = props

    const {t} = useTranslation()



    // const {tab: currentTab} = match.params

    const [helpModalVisible, setHelpModalVisible] = useState(false)
    const [form] = Form.useForm()


    // const currentPage = parseInt(window.location.href?.split('page=')[1]?.split('&')[0] ?? 1)
    // const currentPageSize = parseInt(window.location.href?.split('limit=')[1] ?? 20)

    const onChangeTab = (tab) => {
        history.push(`/audit/${tab}`)
        form.resetFields()
    }




    // useEffect(() => {
    //     dispatch(helpActions.getHelpFetch('audit'))
    // }, [])

    const refreshAudit = () => {
        window.location.reload()
    }



    const onChangePage = (page, pageSize = 20) => {
        let filter = {}

        if (history.location.search.includes('event_date_from')) {
            if (history.location.search.includes('page')) {
                history.push({
                    search: history.location.search.split('&page')[0] + '&page=' + page + '&limit=' + pageSize
                })
            } else {
                history.push({
                    search: history.location.search + '&page=' + page + '&limit=' + pageSize
                })
            }
        } else {
            filter = urlToObject(history.location.search)
            history.push({
                search: objectToUrl({...filter, page, limit: pageSize})
            })
        }
    }

    const onFilter = (value) => {
        value.fromDate = moment(value.event_date[0])
        value.toDate = moment(value.event_date[0])
        delete value.event_date
        auditModel.get(value)
    }

    const sideBarMenuItems = [
        {
            title: t('Refresh'),
            icon: <UndoOutlined/>,
            action: () => refreshAudit()
        }
    ]

    return (
        <>
            <SideBarComponent status={''} menuItems={sideBarMenuItems}/>
            <Layout className={'contentLayout'}>
                <h2
                    className="site-page-header layout_page_header"
                >
                    {t('Audit')}
                </h2>
                <AuditFilter
                    onFilter={onFilter}
                />
                        <AuditList

                            // data={audit}
                            // onChangePage={onChangePage}
                        />
            </Layout>
        </>
    )
})
