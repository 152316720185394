import React, { useEffect } from 'react';
import Descriptions from 'antd/es/descriptions';
import Space from 'antd/es/space';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Divider from 'antd/es/divider';
import Button from 'antd/es/button';
import { PhoneInput } from "../../components/phoneInput.jsx";

export const Profile = ({ user, onSubmit }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(user);
    }, [user]);

    return (
        <Space style={{ width: '100%' }} direction="vertical" size={'large'}>
            <Descriptions bordered column={1} style={{ maxWidth: 500 }}>
                <Descriptions.Item label="Fill name">
                    user desk
                </Descriptions.Item>
                <Descriptions.Item label="Username">USERname</Descriptions.Item>
            </Descriptions>

            <Divider style={{ margin: 8 }} />

            <Form
                form={form}
                initialValues={user}
                style={{ maxWidth: 500 }}
                onFinish={onSubmit}
                autoComplete="off"
            >
                <Form.Item
                    name="email"
                    rules={[{ type: 'email', message: 'Email is not valid' }]}
                >
                    <Input placeholder="Email" autoComplete="off" />
                </Form.Item>

                <Form.Item name="phone">
                    <PhoneInput placeholder="Phone number" />
                </Form.Item>

                <Form.Item name="tg_id">
                    <Input placeholder="Telegram ID" />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Space>
    );
};
