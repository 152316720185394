import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import { Menu } from 'antd';
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import {authModel} from "../../model/auth.js";
import {profileModel} from "../../model/profleModel.js";
import { observer } from 'mobx-react-lite';
import Select from "antd/es/select";
import {autorun} from "mobx";
import {iabModel} from "../../model/iabModel.js";
import {localstorageModel} from "../../model/localstorageModel.js";
// import i18next from "i18next";
import i18next from '../../i18n.js';
import {useTranslation} from "react-i18next";
export const Nav = observer(() => {
    const [language, setLanguage] = useState(i18next.language);
    console.log('i18next.language', i18next.language)
    const handleLanguageChange = (value) => {
        // debugger
        localstorageModel.onChange(value)
        setLanguage(value);
        i18next.changeLanguage(value)
        localStorage.setItem('language', value);
        // console.log('localStorage',value)
    };

    const {t} = useTranslation()


    const widthUser = {
        marginLeft: '100px',
        background: '#001529',
        color: 'lightgray',
    }

    const width = {
        marginLeft: '100px',
        background: '#001529',
        color: 'lightgray',
        width: '60%'
    }
    const flex = {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '90%',
        width: '90%',
        color: 'lightgray',
        margin: '0 auto'
    }

    const menuItems = [
        {
            key: 'Additional',
            label: t('Additional'),
            children: [
                {
                    key: 'languages',
                    label: <Link to="/languages">{t('Languages')}</Link>,
                },
                {
                    key: 'countries',
                    label: <Link to="/countries">{t('Countries')}</Link>,
                },
                {
                    key: 'domains',
                    label: <Link to="/domain-zones">{t('Domain zones')}</Link>,
                },
                {
                    key: 'brands',
                    label: <Link to="/brands">{t('Brands')}</Link>,
                },
                {
                    key: 'brand-sources',
                    label: <Link to="/brand-sources">{t('Brands sources')}</Link>,
                },

            ]
        },
        {
            key: 'Hosts',
            label: t('Hosts'),
            children: [
                {
                    key: 'hosts',
                    label: <Link to="/hosts">{t('Hosts')}</Link>,
                },
                {
                    key: 'hosts-taxonomy',
                    label: <Link to="/hosts-taxonomy">{t('Hosts Taxonomy')}</Link>,
                },
                {
                    key: 'hosts-sources',
                    label: <Link to="/hosts-sources">{t('Hosts Sources')}</Link>,
                },
                {
                    key: 'interest',
                    label: <Link to="/interest">{t('Interest')}</Link>,
                },
                {
                    key: 'classes',
                    label: <Link to="/classes">{t('Classes')}</Link>,
                },
            ]
        },
        {
            key: 'Admin',
            label: t('Admin'),
            children: [
                {
                    key: 'users',
                    label: <Link to="/users">{t('Users')}</Link>,
                },
                {
                    key: 'audit',
                    label: <Link to="/audit">{t('Audit')}</Link>,
                },
            ]
        },




    ]

    const languageItems = [
        {
            label: 'RU',
            value: 'ru',
        },
        {
            label: 'KZ',
            value: 'kz',
        },
        {
            label: 'EN',
            value: 'en',
        },
    ];
    const logout = () => {
        authModel.Logout()
    }

    const menuItemsUser = [
        {
            key: 'submenu',
            label: profileModel?.data?.name || 'username',
            icon: <UserOutlined style={{color: '#fff'}} />,
            children: [
                {
                    key: 'profile',
                    label:<Link to="/profile">{profileModel?.data?.name || 'username'}</Link>,
                    icon: <UserOutlined/>
                },
                {
                    key: 'logout',
                    label:<Link to="/sites-category">Logout</Link>,
                    icon: <LogoutOutlined/>,
                    onClick: logout
                }
            ]
        },

    ]


    return (
        <>
            <div style={flex}>

                <Menu
                    mode="horizontal"
                    defaultSelectedKeys={['home']}
                    style={width}
                    id="menu"
                    items={menuItems}
                />
                <Select
                    key={language}
                    style={{
                    width: 70,margin: 'auto',
                }}
                    defaultValue={language}
                    // onChange={handleLanguageChange}
                    onChange={handleLanguageChange}
                    options={languageItems}
                />
                <Menu
                    style={widthUser}
                    id="submenu"
                    mode="horizontal"
                    defaultSelectedKeys={['username']}
                    items={menuItemsUser}
                />

                {/*    <Menu.SubMenu style={{marginRight: '0', marginLeft: '0', color: "#fff"}} key="username"*/}
                {/*                  icon={<UserOutlined style={{color: '#fff'}} />}*/}
                {/*                  title="USERname FIXME"*/}
                {/*    >*/}
                {/*        <Menu.Item key="17" icon={<UserOutlined/>}>*/}
                {/*            <Link to="/profile">*/}
                {/*                USERname FIXME*/}
                {/*            </Link>*/}
                {/*        </Menu.Item>*/}
                {/*        <Menu.Item key="19" icon={<LogoutOutlined/>} onClick={mainModel.auth.onRevoke}>*/}
                {/*            Sign out*/}
                {/*        </Menu.Item>*/}
                {/*    </Menu.SubMenu>*/}
                {/*</Menu>*/}

            </div>
        </>
    )
});


