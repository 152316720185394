import React, {useEffect} from 'react'
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Divider from "antd/es/divider";
import Tabs from "antd/es/tabs";
// import {PermissionsTable} from "../../pages/PermissionsTable/PermissionsTable";
import {normalizeName} from "../../tools/validateNames";
// import {UniqueValueNotification} from "../../../../tools/UniqueValueNotification";
// import LimitsForm from "../../pages/LimitsForm/LimitsForm";
import './UsersGroupsModalsStyles.css';
import {DatePicker} from "antd";
import {useTranslation} from "react-i18next";

const {TabPane} = Tabs;

const {Option} = Select


const layout = {
    layout: 'vertical'
};


export const CreateUpdateUsersModal = (props) => {

    const {
        updateUser,
        createUser,
        onCancel,
        onSubmit,
        fields,
        users,
        history,
        pathAction,
    } = props



    const {tabName} = 'test';

    const {t} = useTranslation()
    const checkName = (_, value) => {
        const validName = new RegExp('^[_a-z][_a-z0-9]+$')
        if (value && !validName.test(value)) {
            return Promise.reject(new Error('Login could contain only letters, numbers, - and _'));
        }
        if (value?.length <= 3) {
            return Promise.reject(new Error('Enter minimum 3 letters!'));
        }
        if (value && users.findIndex(item => item.username === value) !== -1) {
            UniqueValueNotification('login')
            return Promise.reject(new Error('Enter unique login!'));
        }
        return Promise.resolve()
    }


    const onChangeTab = (tabName) => {
        if (updateUser) {
            history.push(`/users/users/${updateUser.id}/updateUser`)
        }
        if (createUser) {
            history.push(`/users/${pathAction}`)
        }
    };

    const style = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
    }

    const width = {
        width: '100%'
    }

    const tabActiveKey = createUser
        ? tabName
        : updateUser;


    return (
        <Modal title={updateUser ? `${t('Update Host Source')} - ${updateUser?.name}` : t("Create Host Source")}
               wrapClassName='createUpdateUsersModal'
               visible={updateUser || createUser}
               onCancel={onCancel}
               style={{top: 10}}
               destroyOnClose={true}
               footer={[
                   <div key={1} style={{padding: '0 8px'}}>
                       <Button onClick={onCancel}>
                           {t('Cancel')}
                       </Button>
                       <Button form="userForm" type={"primary"} htmlType="submit">
                           {t('Save')}
                       </Button>
                   </div>
               ]}
               width={600}>
                    <Form
                        id="userForm"
                        {...layout}
                        name="userForm"
                        fields={fields}
                        style={style}
                        onFinish={onSubmit}
                    >
                        <Row justify={'space-between'}>
                            <Col>
                                <Form.Item
                                    label={t("Name")}
                                    name="name"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={t("Description")}
                                    name="description"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'space-between'}>

                            <Col>
                                <Form.Item
                                    label={t('Description EN')}
                                    name="description_en"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={t("Description KZ")}
                                    name="description_kz"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify={'space-between'}>
                            <Col >
                                <Form.Item
                                    label={t("Url")}
                                    name="url"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label={t("Received at")}
                                    name="received_at"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <DatePicker style={width}/>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
        </Modal>



    )
}
