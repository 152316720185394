import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";

export const interestModel = makeAutoObservable({
   data: [],
   status: false,
   current: null,
   current_category: null,
   error: null,

   get: async () => {
      try {
         const payload = await instance().get('/interests/').then(res => res.data)
         // check THIS FIXME

         // payload.data.map(item => {
         //    return {
         //       created_at: item.created_at,
         //       created_by: item.created_by,
         //       description: item.description,
         //       id: item.id,
         //       name: item.name,
         //       parent_id: item.parent_id.toString(),
         //       rules: JSON.parse(item.rules),
         //       updated_at: item.updated_at,
         //       updated_by: item.updated_by
         //
         //    }
         // })


         interestModel.data = payload?.data?.map(item => {
            return {
               created_at: item.created_at,
               created_by: item.created_by,
               description: item.description,
               description_en: item.description_en,
               description_kz: item.description_kz,
               id: item.id,
               name: item.name,
               name_en: item.name_en,
               name_kz: item.name_kz,
               parent_id: item.parent_id != null ? item.parent_id.toString() : null,
               // rules: Object.entries(item.rules).length > 0 ? JSON.parse(item.rules) : {},
               // rules: Object.entries(item.rules).length > 0 ? JSON.stringify(item.rules) : ,
               rules: JSON.stringify(item.rules),
               updated_at: item.updated_at,
               updated_by: item.updated_by
            }
         })
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   create: async (payload) => {
      try {
        const newObj =  await instance().post('/interests/', payload).then(res => res.data)
         interestModel.status = 'create'
         interestModel.current = newObj.data.username
         await interestModel.get()
         // check await? FIXME usersModel.getUsers()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   delete: async (id, username) => {
      try {
         await instance().delete(`/interests/purge/${id}`).then(res => res.data)
         interestModel.status = 'delete'
         interestModel.current = username
         await interestModel.get()

      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   update: async (payload, id) => {
      try {
         await instance().put(`/interests/${id.id}`, payload).then(res => res.data)
         await interestModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   addCurrentCategory: async (payload) => {
      try {
         interestModel.current_category = payload
      } catch (e) {
         console.log('iabModel CATCH', e)
      }
   },

});
