import React, {useEffect} from 'react'
import Button from "antd/es/button";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Table from "antd/es/table";
import Popconfirm from "antd/es/popconfirm";
import {Content} from "antd/es/layout/layout";
import Tooltip from "antd/es/tooltip";
import {Link} from "react-router-dom";
import Spin from "antd/es/spin";
import {autorun} from "mobx";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { observer } from 'mobx-react-lite';
import {brandsModel} from "../../model/brandsModel.js";
import {brandsSourcesModel} from "../../model/brandsSourcesModel.js";

import {countriesModel} from "../../model/countriesModel.js";
import {profileModel} from "../../model/profleModel.js";
import {hostsModel} from "../../model/hostsModel.js";
import {iabModel} from "../../model/iabModel.js";
import {localstorageModel} from "../../model/localstorageModel.js";
import {useTranslation} from "react-i18next";


export const BrandsList = observer((props) => {
    const {
        onOpenModal,
    } = props
    const current_lang = localstorageModel.language
    const hostsDictionary = hostsModel.dictionary



    const deleteUser = (id, name) => {
        brandsModel.delete(id, name)
    }

    const {t} = useTranslation()

    const columns = [
        {
            title: 'ID',
            width: '15%',
            dataIndex: 'id',
        },
        {
            title: t('Name'),
            width: '15%',
            dataIndex: 'name',
        },
        {
            title: t('Country'),
            dataIndex: 'country_id',
            width: '20%',
            render: country => countriesModel.dictionary[country]

        },
        {
            title: t('Site url'),
            dataIndex: 'site_url',
            width: '20%',
        },
        {
            title: t('Host Taxonomy'),
            dataIndex: 'host_taxonomy_ids',
            width: '20%',
            // render: host => hostsModel.dictionary[host]
            // render: host => host.map(item => {
            //     return iabModel.dictionary[item]
            // })
            render:  (id) => (
                <>
                    {
                        id?.map(item => {
                            return (
                                <p style={{borderBottom:  '1px solid lightgray', width: 'auto'}}>
                                    {
                                        hostsDictionary[current_lang] ? hostsDictionary[current_lang][item] : '-'
                                    }
                                </p>
                            )
                        })
                    }
                </>
            ),
        },
        {
            title: t('Actions'),
            align: 'center',
            width: '10%',
            className: 'table-operations',
            render: user =>
                <Spin spinning={user.status === 'processing'}>
                    <Button.Group style={{display: 'flex', flexWrap: 'wrap', width: '46px', justifyContent: 'center', margin: '0 auto'}}>
                            <Tooltip placement="topLeft" title={t('Update Brand')}>
                                <Link >
                                    <Button type="default" onClick={() => onOpenModal('update', user)}>
                                        <EditOutlined/>
                                    </Button>
                                </Link>
                            </Tooltip>
                        <Popconfirm
                            title={t(`Are you sure to delete this Brand?`)}
                            onConfirm={() => deleteUser(user.id, user.name)}
                            okText={t("Yes")}
                            cancelText={t("No")}
                        >
                            <Tooltip placement="topLeft" title={t('Delete this Brand')}>
                                <Button type="default" style={{marginLeft: 0}}>
                                    <DeleteOutlined />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </Button.Group>
                </Spin>
        }
    ];

    useEffect(
        () =>
            autorun(() => {
                brandsModel.get()
                hostsModel.get()
                brandsSourcesModel.get()
                countriesModel.get()
                profileModel.get()
            }),
        [],
    )
    const сountries = countriesModel.data
    const data = brandsModel.data
    const brands = brandsSourcesModel.data


    // const data = classModel.classes.map(item => {
    //     return {
    //         created_at: item.created_at,
    //         created_by: item.created_by,
    //         description: item.description,
    //         id: item.id,
    //         name: item.name,
    //         updated_at: item.updated_at,
    //         updated_by: item.updated_by
    //     }
    // })


    return (
        <Content>
            <Table
                bordered
                id='usersListTable'
                size={'small'}
                dataSource={data}
                columns={columns}
                pagination={{
                    position: ['bottomCenter'],
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    pageSizeOptions: [20, 50, 100, 200]
                }}
            />

        </Content>
    )
});

