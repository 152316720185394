import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";

export const hostsModel = makeAutoObservable({
   data: [],
   data_lang: [],
   dictionary: [],
   dictionary_two: [],
   status: false,
   current: null,
   error: null,
   hostsSummary: {},
   hostsSummaryArr: [],
   currentModel: [],
   currentManual: [],
   currentExlude: [],
   currentSite: [],
   calcSummary: (value) => {
      value.map(item => {
         if (hostsModel.hostsSummary[item]) {
            delete hostsModel.hostsSummary[item]
         }
      })
      hostsModel.hostsSummaryArr = Object.values(hostsModel.hostsSummary).map(
          item => {return hostsModel.dictionary[item]}
      )
   },

   autoSummary: () => {
      hostsModel.hostsSummary = {}
      hostsModel.hostsSummaryArr = []


      const current_model_ids = hostsModel.currentSite.host_taxonomy_model_ids
      // hostsModel.currentExlude = hostsModel.currentSite.host_taxonomy_exclude_ids
      // const current_model_ids = ['15', '20']

      hostsModel.currentModel = current_model_ids

      // debugger

      hostsModel.currentManual?.map(item => {
         if (!hostsModel.hostsSummary[item]) {
            hostsModel.hostsSummary[item] = parseInt(item)
         }
      })

      hostsModel.currentModel?.map(item => {
         if (!hostsModel.hostsSummary[item]) {
            hostsModel.hostsSummary[item] = parseInt(item)
         }
      })

      hostsModel.currentExlude?.map(item => {
         if (hostsModel.hostsSummary[item]) {
            delete hostsModel.hostsSummary[item]
         }
      })

      // hostsModel.currentSite.host_taxonomy_exclude_ids?.map(item => {
      //    if (hostsModel.hostsSummary[item]) {
      //       delete hostsModel.hostsSummary[item]
      //    }
      // })

      // debugger





       hostsModel.hostsSummaryArr = Object.values(hostsModel.hostsSummary)?.map(
          // item => {return hostsModel.dictionary[item]}
          item => {return parseInt(item)}
      )


   },

   addManual: (value) => {
      hostsModel.currentManual = value
      hostsModel.autoSummary()
   },
   addExclude: (value) => {
      hostsModel.currentExlude = value
      hostsModel.autoSummary()
   },
   open: (currentSite) => {
      // const current_model_ids = [
      //    10, 11, 12, 13, 14
      // ]
      // FIXME ^
      // FIXME ADD REAL DATA
      // FIXME >

      hostsModel.currentSite = currentSite
      // debugger
      const current_model_ids = currentSite?.host_taxonomy_model_ids

      hostsModel.currentExlude = currentSite?.host_taxonomy_exclude_ids
      hostsModel.currentManual = currentSite?.host_taxonomy_manual_ids
      hostsModel.currentModel = current_model_ids
      hostsModel.autoSummary()
   },

   get: async () => {
      try {
         const payload = await instance().get('/host_taxonomy/').then(res => res.data)
         hostsModel.data = payload.data

         hostsModel.dictionary.ru = Object.fromEntries(payload.data.map(
             item => [
                item.id,
                [
                   item.tier_1 ? item.tier_1[`name_${'ru'}`] : '',
                   item.tier_2 ? item.tier_2[`name_${'ru'}`] : '',
                   item.tier_3 ? item.tier_3[`name_${'ru'}`] : '',
                   item.tier_4 ? item.tier_4[`name_${'ru'}`] : ''
                ].filter(name => name !== '').join(' > '),
             ]
         ));


         hostsModel.dictionary.kz = Object.fromEntries(payload.data.map(
             item => [
                item.id,
                [
                   item.tier_1 ? item.tier_1[`name_${'kz'}`] : '',
                   item.tier_2 ? item.tier_2[`name_${'kz'}`] : '',
                   item.tier_3 ? item.tier_3[`name_${'kz'}`] : '',
                   item.tier_4 ? item.tier_4[`name_${'kz'}`] : ''
                ].filter(name => name !== '').join(' > '),
             ]
         ));
         hostsModel.dictionary.en = Object.fromEntries(payload.data.map(
             item => [
                item.id,
                [
                   item.tier_1 ? item.tier_1[`name_${'en'}`] : '',
                   item.tier_2 ? item.tier_2[`name_${'en'}`] : '',
                   item.tier_3 ? item.tier_3[`name_${'en'}`] : '',
                   item.tier_4 ? item.tier_4[`name_${'en'}`] : ''
                ].filter(name => name !== '').join(' > '),
             ]
         ));

         hostsModel.data_lang = payload.data.map(item => ({
            id: item.id,
            name_ru_lvl: [
               item.tier_1 ? item.tier_1[`name_${'ru'}`] : '',
               item.tier_2 ? item.tier_2[`name_${'ru'}`] : '',
               item.tier_3 ? item.tier_3[`name_${'ru'}`] : '',
               item.tier_4 ? item.tier_4[`name_${'ru'}`] : ''
            ].filter(name => name !== '').join(' > '),
            name_kz_lvl: [
               item.tier_1 ? item.tier_1[`name_${'kz'}`] : '',
               item.tier_2 ? item.tier_2[`name_${'kz'}`] : '',
               item.tier_3 ? item.tier_3[`name_${'kz'}`] : '',
               item.tier_4 ? item.tier_4[`name_${'kz'}`] : ''
            ].filter(name => name !== '').join(' > '),
            name_en_lvl: [
               item.tier_1 ? item.tier_1[`name_${'en'}`] : '',
               item.tier_2 ? item.tier_2[`name_${'en'}`] : '',
               item.tier_3 ? item.tier_3[`name_${'en'}`] : '',
               item.tier_4 ? item.tier_4[`name_${'en'}`] : ''
            ].filter(name => name !== '').join(' > '),
            created_at: item.created_at,
            created_by: item.created_by,
            description: item.description,
            description_en: item.description_en,
            description_kz: item.description_kz,
            parent_id:item.parent_id,
            updated_at: item.updated_at,
            updated_by: item.updated_by,
            tier_level: item.tier_level,
         }));

         hostsModel.data_lang = hostsModel.data_lang.map(item => {
            console.log('hostsModel.data_lang.map', item)
            return {
               ...item,
               name_ru: item.name_ru_lvl.split('>')[item.name_ru_lvl.split('>').length - 1].trim(),
               name_en: item.name_en_lvl.split('>')[item.name_en_lvl.split('>').length - 1].trim(),
               name_kz: item.name_kz_lvl.split('>')[item.name_kz_lvl.split('>').length - 1].trim(),
            }
         })


      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   create: async (payload) => {
      try {
        const newObj =  await instance().post('/host_taxonomy/', payload).then(res => res.data)
         hostsModel.status = 'create'
         hostsModel.current = newObj.data.description
         await hostsModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   delete: async (id, name) => {
      try {
         await instance().delete(`/host_taxonomy/purge/${id}`).then(res => res.data)
         hostsModel.status = 'delete'
         hostsModel.current = name
         await hostsModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   update: async (payload, id) => {
      try {
         await instance().put(`/host_taxonomy/${id}`, payload).then(res => res.data)
         hostsModel.status = 'update'
         hostsModel.current = payload.description
         await hostsModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },

});
