import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";

export const countriesModel = makeAutoObservable({
   data: [],
   dictionary: [],
   status: false,
   current: null,
   error: null,

   get: async () => {
      try {
         const payload = await instance().get('/countries/').then(res => res.data)
         countriesModel.data = payload.data
         countriesModel.dictionary = Object.fromEntries(payload.data.map(
             item => [item.id, item.name])
         )
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   create: async (payload) => {
      try {
        const newObj =  await instance().post('/countries/', payload).then(res => res.data)
         countriesModel.status = 'create'
         countriesModel.current = newObj.data.name
         await countriesModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   delete: async (id, name) => {
      try {
         await instance().delete(`/countries/purge/${id}`).then(res => res.data)
         countriesModel.status = 'delete'
         countriesModel.current = name
         await countriesModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   update: async (payload, id) => {
      try {
         await instance().put(`/countries/${id}`, payload).then(res => res.data)
         countriesModel.status = 'update'
         countriesModel.current = payload.name
         await countriesModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   clear: () => {
      countriesModel.status = null,
      countriesModel.current = null
      countriesModel.error = null

   }

});
