import React from 'react'
import Form from "antd/es/form";
import {EditableTable} from "./EditableTable.jsx";
import Card from "antd/es/card";
import Button from "antd/es/button";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import {v4 as uuidv4} from "uuid";

export const RulesComponent = ({
                                   ruleType,
                                   form,
                                   rules,
                                   setRules,
                                   columns,
                                   setDependsRules
                               }) => {

    const onAddRules = (value) => {
        setRules(prev => [...prev, {...value, key: uuidv4()}])
        form.resetFields()
        setDependsRules(prev => {
            const temp = prev
            temp[ruleType].level = null
            return temp
        })
    }

    return (
        <Card>
            <Form
                style={{marginBottom: 15}}
                id={`${ruleType}RulesForm`}
                layout={'inline'}
                form={form}
                name={`${ruleType}RulesForm`}
                onFinish={v => onAddRules(v)}
            >
                {
                    columns(ruleType).filter(col => !col.removed && !col.operations).map(col =>
                        <Form.Item
                            key={`title-${col.title}`}
                            label={col.title}
                            name={col.dataIndex}
                            // className={'terms_modal_propagate_input'}
                            {...col.form}
                        >
                            {col.form.render}
                        </Form.Item>
                    )
                }
                <Button
                    type="primary"
                    form={`${ruleType}RulesForm`}
                    htmlType="submit"
                    style={{width: '80px', marginLeft: 'auto'}}>
                    Add <PlusOutlined/>
                </Button>
            </Form>
            {rules.length ? <EditableTable
                setData={setRules}
                columns={columns(ruleType)}
                bordered
                dataSource={rules}
                pagination={{position: ['bottomCenter']}}
            /> : null}
        </Card>
    )
}
