import React, {useEffect} from 'react'
import Button from "antd/es/button";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Table from "antd/es/table";
import Badge from "antd/es/badge";
import {COLORS} from "../../constants/colors";
import {ChangePasswordModal} from "./ChangePasswordModal";
import Popconfirm from "antd/es/popconfirm";
import {Content} from "antd/es/layout/layout";
import Tooltip from "antd/es/tooltip";
import {StopFilled, ZhihuSquareFilled} from "@ant-design/icons";
import CheckCircleFilled from "@ant-design/icons/CheckCircleFilled";
import {Link} from "react-router-dom";
import Spin from "antd/es/spin";
import {Observer} from "mobx-react";
import {usersModel} from "../../model/usersModel.js";
import {autorun} from "mobx";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { observer } from 'mobx-react-lite';
import {profileModel} from "../../model/profleModel.js";
import {useTranslation} from "react-i18next";

export const UsersList = observer((props) => {
    const {
        departments,
        setChangePasswordModal,
        changePasswordModal,
        onOpenModal,
        onCancelChangePassword,
        onCancel,
    } = props


    const onChangePassword = (value, id) => {
        usersModel.updateUser(value, id)
        onCancel()
        onCancelChangePassword()
    }

    const onChangeActivity = (user) => {
        if (user.is_blocked === false) {
            usersModel.blockUser(user.id, user.username)
        } else {
            usersModel.unblockUser(user.id, user.username)
        }
    }
    const deleteUser = (id, username) => {
        usersModel.deleteUser(id, username)
    }

    const {t} = useTranslation()

    const columns = [
        {
            title: t('Name'),
            width: '15%',
            dataIndex: 'name',
        },
        {
            title: t('Login'),
            dataIndex: 'username',
            width: '10%',
            render: (text, user) =>
                <Badge.Ribbon
                    color={!user.is_blocked ? COLORS.GREEN : COLORS.GRAY}
                    text={!user.is_blocked ? 'Active' : 'Inactive'}
                    style={{fontSize: '10px', height: '17px', lineHeight: 1.5, top: '-20px', right: '-16px', zIndex: 100}}
                >
					<span>
						{text}
					</span>
                </Badge.Ribbon>,
        },
        {
            title: t('Department'),
            dataIndex: 'department',
            width: '20%',
            filters: departments?.map(item => ({text: item.name, value: item.name})),
        },
        {
            title: t('Description'),
            dataIndex: 'description',
            width: '20%',
            filters: departments?.map(item => ({text: item.name, value: item.name})),
        },
        {
            align: t('center'),
            width: '10%',
            className: 'table-operations',
            render: user =>
                <Spin spinning={user.status === 'processing'}>
                    <Button.Group style={{display: 'flex', flexWrap: 'wrap', width: '46px', justifyContent: 'center', margin: '0 auto'}}>
                            <Tooltip placement="topLeft" title={t('Update User')}>
                                <Link >
                                    <Button type="default" onClick={() => onOpenModal('update', user)}>
                                        <EditOutlined/>
                                    </Button>
                                </Link>
                            </Tooltip>
                            <Tooltip placement="topLeft" title={t('Change password')}>
                                <Button type="default" onClick={() => setChangePasswordModal(user)}>
                                    <ZhihuSquareFilled/>
                                </Button>
                            </Tooltip>
                            <Popconfirm
                                title={`${t('Are you sure to ')} ${!user.is_blocked ? t("Inactivate") : t("Activate")} ${t('this User?')}`}
                                onConfirm={() => onChangeActivity(user)}
                                okText={t("Yes")}
                                cancelText={t("No")}
                            >
                                <Tooltip placement="topLeft" title={t('Activate / Inactivate this User')}>
                                    <Button type="default" style={{marginLeft: 0}}>
                                        {   !user.is_blocked ? <StopFilled/> : <CheckCircleFilled/>}
                                    </Button>
                                </Tooltip>
                            </Popconfirm>

                        <Popconfirm
                            title={t(`Are you sure to delete this User?`)}
                            onConfirm={() => deleteUser(user.id, user.username)}
                            okText={t("Yes")}
                            cancelText={t("No")}
                        >

                            <Tooltip placement="topLeft" title={t('Delete this user')}>
                                <Button type="default" style={{marginLeft: 0}}>
                                    <DeleteOutlined />
                                </Button>
                            </Tooltip>
                        </Popconfirm>

                    </Button.Group>
                </Spin>
        }
    ];

    useEffect(
        // FIXME useEffect GO TO MODEL!! OR CONTAINER
        () =>
            autorun(() => {
                console.log('useEffect ReRender::')
                usersModel.getUsers()
                profileModel.get()
            }),
        [],
    )

    const users = usersModel.users



    return (
        <Content>
            <ChangePasswordModal
                onCancel={onCancelChangePassword}
                onSubmit={onChangePassword}
                changePasswordModal={changePasswordModal}
            />
            {/*FIXME add to model userid URL, change pass momdal to container*/}
            <Table
                        bordered
                        id='usersListTable'
                        size={'small'}
                        dataSource={users}
                        // FIXME usersModel.users чекнуть можно ли отдельно поставить наблюдателя
                        pagination={{
                            position: ['bottomCenter'],
                            defaultPageSize: 20,
                            showSizeChanger: true,
                            pageSizeOptions: [20, 50, 100, 200]
                        }}
                        columns={columns}
                    />
        </Content>
    )
});

