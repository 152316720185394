import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";
import {localstorageModel} from "./localstorageModel.js";

export const iabModel = makeAutoObservable({
    data: [],
   dictionary: [],
   status: false,
   current: null,
   current_category: null,
   error: null,
    data_lang: [],


   get: async () => {
      try {
         const payload = await instance().get('/iab_taxonomy/').then(res => res.data)
         // check THIS FIXME
         iabModel.data = payload.data
          let lang = localstorageModel.language
          iabModel.dictionary.ru = Object.fromEntries(payload.data.map(
              item => [
                  item.id,
                  [
                      item.tier_1? item.tier_1[`name_${'ru'}`] : '',
                      item.tier_2 ? item.tier_2[`name_${'ru'}`] : '',
                      item.tier_3 ? item.tier_3[`name_${'ru'}`] : '',
                      item.tier_4 ? item.tier_4[`name_${'ru'}`] : ''
                  ].filter(name => name !== '').join(' > '),
              ]
          ));
          iabModel.dictionary.kz = Object.fromEntries(payload.data.map(
              item => [
                  item.id,
                  [
                      item.tier_1? item.tier_1[`name_${'kz'}`] : '',
                      item.tier_2 ? item.tier_2[`name_${'kz'}`] : '',
                      item.tier_3 ? item.tier_3[`name_${'kz'}`] : '',
                      item.tier_4 ? item.tier_4[`name_${'kz'}`] : ''
                  ].filter(name => name !== '').join(' > '),
              ]
          ));
          iabModel.dictionary.en = Object.fromEntries(payload.data.map(
              item => [
                  item.id,
                  [
                      item.tier_1? item.tier_1[`name_${'en'}`] : '',
                      item.tier_2 ? item.tier_2[`name_${'en'}`] : '',
                      item.tier_3 ? item.tier_3[`name_${'en'}`] : '',
                      item.tier_4 ? item.tier_4[`name_${'en'}`] : ''
                  ].filter(name => name !== '').join(' > '),
              ]
          ));
          iabModel.data_lang = payload.data.map(item => ({
              id: item.id,
              name_ru: [
                  item.tier_1 ? item.tier_1[`name_${'ru'}`] : '',
                  item.tier_2 ? item.tier_2[`name_${'ru'}`] : '',
                  item.tier_3 ? item.tier_3[`name_${'ru'}`] : '',
                  item.tier_4 ? item.tier_4[`name_${'ru'}`] : ''
              ].filter(name => name !== '').join(' > '),
              name_kz: [
                  item.tier_1 ? item.tier_1[`name_${'kz'}`] : '',
                  item.tier_2 ? item.tier_2[`name_${'kz'}`] : '',
                  item.tier_3 ? item.tier_3[`name_${'kz'}`] : '',
                  item.tier_4 ? item.tier_4[`name_${'kz'}`] : ''
              ].filter(name => name !== '').join(' > '),
              name_en: [
                  item.tier_1 ? item.tier_1[`name_${'en'}`] : '',
                  item.tier_2 ? item.tier_2[`name_${'en'}`] : '',
                  item.tier_3 ? item.tier_3[`name_${'en'}`] : '',
                  item.tier_4 ? item.tier_4[`name_${'en'}`] : ''
              ].filter(name => name !== '').join(' > '),

              created_at: item.created_at,
              created_by: item.created_by,
              description: item.description,
              parent_id:item.parent_id,
              updated_at: item.updated_at,
              updated_by: item.updated_by,
          }));

      } catch (e) {
         console.log('usersModel CATCH', e)
      }

   },

   create: async (payload) => {
      try {
         if (payload.parent_id === '') {
            payload.parent_id = null;
         }
        const newObj =  await instance().post('/iab_taxonomy/', payload).then(res => res.data)
         iabModel.status = 'create'
         iabModel.current = newObj.data.username
         await iabModel.get()
         // check await? FIXME usersModel.getUsers()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   delete: async (payload) => {

       const deleteType = {
            type: payload.action,
            new_parent: payload.parent_id ? payload.parent_id : null
       }

      try {
         await instance().delete(`/iab_taxonomy/purge/${payload.id}?type=${deleteType.type}&new_parent=${deleteType.new_parent}`).then(res => res.data)
         await iabModel.get()

      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   update: async (payload, object) => {
      try {
         await instance().put(`/iab_taxonomy/${object.id}`, payload).then(res => res.data)
         await iabModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   addCurrentCategory: async (payload) => {
      try {
         iabModel.current_category = payload
      } catch (e) {
         console.log('iabModel CATCH', e)
      }
   },

});
