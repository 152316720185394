import React, {useEffect} from 'react'
import Button from "antd/es/button";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Table from "antd/es/table";
import Popconfirm from "antd/es/popconfirm";
import {Content} from "antd/es/layout/layout";
import Tooltip from "antd/es/tooltip";
import {Link} from "react-router-dom";
import Spin from "antd/es/spin";
import {autorun} from "mobx";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { observer } from 'mobx-react-lite';
import {domainModel} from "../../model/domainModel.js";
import {countriesModel} from "../../model/countriesModel.js";
import {profileModel} from "../../model/profleModel.js";
import {localstorageModel} from "../../model/localstorageModel.js";
import {useTranslation} from "react-i18next";


export const CountriesList = observer((props) => {
    const {
        onOpenModal,
    } = props

    const deleteUser = (id, name) => {
        countriesModel.delete(id, name)
    }
    const {t} = useTranslation()

    const current_lang = localstorageModel.language

    const columns = [
        {
            title: 'ID',
            width: '15%',
            dataIndex: 'id',
        },
        {
            title: t('Name'),
            width: '15%',
            dataIndex: 'name',
            render: (name, item) => current_lang === 'ru' ? item.name :
                item[`name_${current_lang}`] ? item[`name_${current_lang}`] : '-'
        },
        // {
        //     title: 'Eng name',
        //     dataIndex: 'eng_name',
        //     width: '20%',
        // },
        {
            title: t('Domain zone'),
            dataIndex: 'domain_zone_id',
            width: '20%',
        },
        {
            title: t('Actions'),
            align: 'center',
            width: '10%',
            className: 'table-operations',
            render: user =>
                <Spin spinning={user.status === 'processing'}>
                    <Button.Group style={{display: 'flex', flexWrap: 'wrap', width: '46px', justifyContent: 'center', margin: '0 auto'}}>
                            <Tooltip placement="topLeft" title={t('Update Country')}>
                                <Link >
                                    <Button type="default" onClick={() => onOpenModal('update', user)}>
                                        <EditOutlined/>
                                    </Button>
                                </Link>
                            </Tooltip>
                        <Popconfirm
                            title={`Are you sure to delete this Country?`}
                            onConfirm={() => deleteUser(user.id, user.name)}
                            okText={t("Yes")}
                            cancelText={t("No")}
                        >
                            <Tooltip placement="topLeft" title={t('Delete this Country')}>
                                <Button type="default" style={{marginLeft: 0}}>
                                    <DeleteOutlined />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </Button.Group>
                </Spin>
        }
    ];

    useEffect(
        // FIXME useEffect GO TO MODEL!! OR CONTAINER
        () =>
            autorun(() => {
                console.log('useEffect ReRender::')
                countriesModel.get()
                domainModel.get()
                profileModel.get()
            }),
        [],
    )

    const domain_dictionary = domainModel.dictionary
    const data = countriesModel.data.map(item => {
        return {
            created_at: item.created_at,
            created_by: item.created_by,
            domain_zone_id: domain_dictionary[item.domain_zone_id],
            eng_name: item.eng_name,
            id: item.id,
            name: item.name,
            name_en: item.name_en,
            name_kz: item.name_kz,
            updated_at: item.updated_at,
            updated_by: item.updated_by
        }
    })

    console.log(' data', data)

    return (
        <Content>
            <Table
                bordered
                id='usersListTable'
                size={'small'}
                dataSource={data}
                columns={columns}
                pagination={{
                    position: ['bottomCenter'],
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    pageSizeOptions: [20, 50, 100, 200]
                }}
            />

        </Content>
    )
});

