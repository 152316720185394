import React, {useEffect, useState} from 'react'
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Divider from "antd/es/divider";
import Tabs from "antd/es/tabs";
// import {PermissionsTable} from "../../pages/PermissionsTable/PermissionsTable";
import {normalizeName} from "../../tools/validateNames";
// import {UniqueValueNotification} from "../../../../tools/UniqueValueNotification";
// import LimitsForm from "../../pages/LimitsForm/LimitsForm";
import './UsersGroupsModalsStyles.css';
import {useTranslation} from "react-i18next";

const {TabPane} = Tabs;

const {Option} = Select


const layout = {
    layout: 'vertical'
};

const passwordError = {
    border: '1px solid red'
}

const passwordTrue = {
    color: '#000'
}

const passwordDeskShow = {
    display: 'block',
    color: 'red'
}

const passwordDeskHide = {
    display: 'none'
}

const validatePassword = (value) => {
    const validate = /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{10,}/g
    if (validate.test(value) === false) {
        return false
    } else {
        return true
    }
}


export const CreateUpdateUsersModal = (props) => {

    const {
        updateUser,
        createUser,
        onCancel,
        onSubmit,
        fields,
        users,
        onAddPermission,
        subjectPerms,
        onDeletePermissions,
        history,
        pathAction,
    } = props

    const {t} = useTranslation()

    const [form] = Form.useForm();

    const [checkPassword, setCheckPassword] = useState(null)
    const [password, setPassword] = useState(null)
    // const {tabName} = match.params;
    const {tabName} = 'test';

    const checkName = (_, value) => {
        const validName = new RegExp('^[_a-z][_a-z0-9]+$')
        if (value && !validName.test(value)) {
            return Promise.reject(new Error('Login could contain only letters, numbers, - and _'));
        }
        if (value?.length <= 3) {
            return Promise.reject(new Error('Enter minimum 3 letters!'));
        }
        if (value && users.findIndex(item => item.username === value) !== -1) {
            UniqueValueNotification('login')
            return Promise.reject(new Error('Enter unique login!'));
        }
        return Promise.resolve()
    }

    const checkDepartentsInGroup = (_, value) => {

        let differenceDepartmentsOfUser = false
        value.map(item => {
            groups.map(group => {
                if (group.id === item) {

                    //Users in this group
                    group.item_permissions.map(perm => {

                        //if group includes in item_permissions created_by_my_dep obj rule, then all users in group must be in one department
                        if (perm.obj.includes('created_by_my_dep')) {
                            group.users_ids.map(group_user => {
                                users.find(user => {

                                    //check departments for current and departments of users in groups
                                    if (user.id === group_user) {
                                        if (user.department_id !== updateUser?.department_id || user.department_id !== createUser?.department_id) {
                                            differenceDepartmentsOfUser = true
                                        }
                                    }
                                })

                            })
                        }
                    })

                }
            })
        })



        if (differenceDepartmentsOfUser) {
            return Promise.reject(new Error('Enter unique login!'));
        }
        return Promise.resolve()

    }
    const onChangeTab = (tabName) => {
        if (updateUser) {
            history.push(`/users/users/${updateUser.id}/updateUser`)
        }
        if (createUser) {
            history.push(`/users/${pathAction}`)
        }
    };


    // const permissionsData = [
    //     {
    //         value: 'manage_users',
    //         label: 'Manage users',
    //     },
    //     {
    //         value: 'manage_categories',
    //         label: 'Manage categories',
    //     },
    //     {
    //         value: 'manage_sites',
    //         label: 'Manage sites',
    //     },
    //     {
    //         value: 'manage_interests',
    //         label: 'Manage interests',
    //     },
    // ]

    const permissionsData = [

        {
            label: 'Manage users',
            value: 'manage_users',
        },

        {
            label: 'Audit',
            value: 'audit',
        },

        {
            label: 'Manage languages',
            value: 'manage_languages',
        },

        {
            label: 'Manage countries',
            value: 'manage_countries',
        },

        {
            label: 'Manage domain zones',
            value: 'manage_domain_zones',
        },

        {
            label: 'Manage brands',
            value: 'manage_brands',
        },

        {
            label: 'Manage brand sources',
            value: 'manage_brand_sources',
        },

        {
            label: 'Manage classes',
            value: 'manage_classes',
        },

        {
            label: 'Manage iab taxonomy',
            value: 'manage_iab_taxonomy',
        },

        {
            label: 'Manage interests',
            value: 'manage_interests',
        },

        {
            label: 'Manage host taxonomy',
            value: 'manage_host_taxonomy',
        },

        {
            label: 'Manage sites',
            value: 'manage_sites',
        },

        {
            label: 'Manage site sources',
            value: 'manage_site_sources',
        },
    ]

    const onChangePassword = (e) => {
        let formData = form.getFieldsValue()
        if (e.target.value.includes(formData.username.toLowerCase())) {
            setCheckPassword(false)
        } else {
            setCheckPassword( validatePassword(e.target.value))
        }
        setPassword(e.target.value)
    }

    const submit = (value) => {
        if (createUser) {
            value.password = password
        }
        onSubmit(value)
    }



    const tabActiveKey = createUser
        ? tabName
        : updateUser;


    return (
        <Modal title={updateUser ? `${t('Update User')} - ${updateUser && (updateUser?.full_name ?? updateUser?.username)}` : t("Create User")}
               wrapClassName='createUpdateUsersModal'
               visible={updateUser || createUser}
               onCancel={onCancel}
               style={{top: 10}}
               destroyOnClose={true}
               footer={[
                   <div key={1} style={{padding: '0 8px'}}>
                       <Button onClick={onCancel}>
                           Cancel
                       </Button>
                       <Button
                           form="userForm"
                           type={"primary"}
                           htmlType="submit"
                           disabled={updateUser ? false : !checkPassword}
                       >
                           Save
                       </Button>
                   </div>
               ]}
               width={1150}>
                    <Form
                        id="userForm"
                        {...layout}
                        name="userForm"
                        fields={fields}
                        form={form}
                        onFinish={submit}
                    >
                        <Row justify={'space-between'}>
                            <Col span={7}>
                                <Form.Item
                                    label={t("Name")}
                                    name="name"
                                    rules={[
                                        {required: true, message: t('Enter first name!')},
                                        {
                                            pattern: '^[а-яА-ЯёЁa-zA-Z]+$',
                                            message: t('Please enter a valid only A-z'),
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>

                            <Col span={7}>
                                <Form.Item
                                    label={t("Username")}
                                    name="username"
                                    rules={[
                                        {required: true, message: t('Enter first name!')},
                                        {
                                            pattern: '^[а-яА-ЯёЁa-zA-Z]+$',
                                            message: t('Please enter a valid only A-z'),
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>

                            <Row justify={'space-between'} style={{width: '29%'}}>
                                <Col style={{width: '100%'}}>
                                    {/*<Form.Item*/}
                                    {/*    label="Password"*/}
                                    {/*    name="password"*/}
                                    {/*    rules={[{required: createUser, message: 'Enter password!'}]}*/}
                                    {/*>*/}
                                    {/*    <Input.Password disabled={!createUser}/>*/}
                                    {/*</Form.Item>*/}


                                    <p style={{
                                        marginBottom: '9px',
                                        marginTop: '0'
                                    }}>
                                        {t('Password')}
                                    </p>
                                    <Input.Password
                                        disabled={!createUser}
                                        value={password}
                                        style={checkPassword === false ? passwordError : passwordTrue}
                                        onChange={onChangePassword}
                                    />
                                </Col>
                            </Row>
                            <Col span={7}>
                                <Form.Item
                                    label={t("Description")}
                                    name="description"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>

                            <Col span={7}>
                                <Form.Item
                                    label={t("Department")}
                                    name="department"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={7}>
                                <Form.Item
                                    label={t("Permissions")}
                                    name="perms"
                                >
                                    <Select
                                        mode="multiple"
                                        options={permissionsData}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
        </Modal>



    )
}
