import React, {useEffect, useState} from 'react'
import Tabs from "antd/es/tabs";
import Layout from "antd/es/layout/layout";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import UndoOutlined from "@ant-design/icons/UndoOutlined";
import {CreateUpdateUsersModal} from "./CreateUpdateModal"
import { SideBarComponent } from "../SideBar/SideBarComponent"
import {changeFormFieldsData} from "../../tools/changeFormFieldsData";

import notification from "antd/es/notification";

import { observer } from 'mobx-react-lite';
import {SitesList} from "./SitesList.jsx";

import Select from "antd/es/select";

import {hostCategoryModel} from "../../model/hostCategoryModel.js";
import {autorun} from "mobx";
import {hostsModel} from "../../model/hostsModel.js";
import {domainModel} from "../../model/domainModel.js";
import {classModel} from "../../model/classModel.js";
import {countriesModel} from "../../model/countriesModel.js";
import {langModel} from "../../model/langModel.js";
import {brandsModel} from "../../model/brandsModel.js";
import {profileModel} from "../../model/profleModel.js";
import {sitesModel} from "../../model/sitesModel.js";
import {useTranslation} from "react-i18next";



const initialFields = [
    {
        name: 'id',
        value: ''
    },
    {
        name: 'name',
        value: ''
    },
    {
        name: 'ip_addresses',
        value: null
    },
    {
        name: 'host_taxonomy_manual_ids',
        value: []
    },
    {
        name: 'host_taxonomy_model_ids',
        value: []
    },
    {
        name: 'host_taxonomy_ids',
        value: []
    },
    {
        name: 'host_taxonomy_exclude_ids',
        value: []
    },
    {
        name: 'domain_zone_id',
        value: ''
    },
    {
        name: 'class_manual_ids',
        value: []
    },
    {
        name: 'class_exclude_ids',
        value: []
    },
    {
        name: 'class_ids',
        value: []
    },
    {
        name: 'class_model_ids',
        value: []
    },
    {
        name: 'rating_class_level',
        value: ''
    },
    {
        name: 'country_id',
        value: []
    },
    {
        name: 'city_geolocation',
        value: ''
    },
    {
        name: 'language_ids',
        value: ''
    },
    {
        name: 'tags',
        value: ''
    },
    {
        name: 'brand_ids',
        value: ''
    },
    {
        name: 'site_source_id',
        value: ''
    },
]

export const SitesContainer = observer(() => {

    const [createUser, setCreateUser] = useState(null)
    // const [changePasswordModal, setChangePasswordModal] = useState(null)
    const [userFields, setUserFields] = useState(initialFields)
    const [updateUser, setUpdateUser] = useState(null)


    const [api, contextHolder] = notification.useNotification();

    const {t} = useTranslation()


    const DeleteNotif = (id) => {
        api.error({
            message: 'Deleted',
            description:
                `Host category ${id} Deleted`,
        });
    };
    const CreateNotif = (username) => {
        api.success({
            message: 'Created',
            description:
                `Host category ${username} created`,
        });
    };

    const UpdateNotif = (username) => {
        api.success({
            message: 'Updated',
            description:
                `Host category ${username} updated`,
        });
    };


    if (hostCategoryModel.status) {
        if (hostCategoryModel.status === 'delete') {
            // DeleteNotif(hostCategoryModel.current)
        }
        if (hostCategoryModel.status === 'create') {
            // CreateNotif(hostCategoryModel.current)
        }
        if (hostCategoryModel.status === 'update') {
            // UpdateNotif(hostCategoryModel.current)
        }
    }

    const onCancel = () => {
        setCreateUser(null)
        setUpdateUser(null)
        setUserFields(initialFields)
    }

    const onOpenUserModal = (type, value) => {
        if (type === 'create') {
            setCreateUser(true)
        } else if (type === 'update') {
            // let a = hostCategoryModel.getInfo(value.id)
            // const currentSite = hostCategoryModel.currentSite
            const currentSite = hostCategoryModel.data?.sites?.filter(item => item.id == value.id)[0]

            // FIXME use back data class_model_ids
            // currentSite.class_exclude_ids = model
            // currentSite.host_taxonomy_exclude_ids = model


            // FIXME USE GET INFO CURRENT SITE

            // FIXME UPDATE DATA WARNING !!!


            classModel.open(currentSite)
            hostsModel.open(currentSite)
            // currentSite.host_taxonomy_manual_ids
            // const tempTaxonomy = currentSite.host_taxonomy_manual_ids.map(item => {
            //     return {
            //         id: item,
            //         description: hostsModel.dictionary[item]
            //     }
            // })

            // currentSite.host_taxonomy_manual_ids = tempTaxonomy

            const tempTaxonomyIds = currentSite.host_taxonomy_manual_ids?.map(
                item => {return parseInt(item)}
            )

            const tempTaxonomyModelIds = currentSite.host_taxonomy_model_ids?.map(
                item => {return parseInt(item)}
            )



            currentSite.host_taxonomy_manual_ids = tempTaxonomyIds
            currentSite.host_taxonomy_model_ids = tempTaxonomyModelIds



            setUserFields(changeFormFieldsData(userFields, currentSite))
            setUpdateUser(value)
        }
    }



    const onUserSubmit = (value) => {
        if (createUser) {
            const payload = value
            //
            // payload.host_taxonomy_manual_ids = value.host_taxonomy_manual_ids?.map(item => {
            //     return item
            // })

            hostCategoryModel.create(payload)
        } else if (updateUser) {
            const payload = value
            // payload.host_taxonomy_manual_ids = value.host_taxonomy_manual_ids?.map(item => {
            //     return item.toString()
            //     return item.toString()
            // })

            // payload.host_taxonomy_exclude_ids = value.host_taxonomy_exclude_ids?.map(item => {
            //     return item.toString()
            // })


            hostCategoryModel.update(payload, updateUser.id)
        }
        onCancel()
    }

    const refresh = () => {
        window.location.reload()
    }

    const sideBarMenuItems = [
        {
            title: t('Create Host'),
            icon: <PlusCircleOutlined/>,
            action: () => onOpenUserModal('create'),
            // link: '/users/createUser/main',
        },
        {
            title: t('Refresh'),
            icon: <UndoOutlined/>,
            action: refresh
        }
    ]

    return (
        <>
            <SideBarComponent
                menuItems={sideBarMenuItems}
            />

            {contextHolder}
            <CreateUpdateUsersModal
                updateUser={updateUser}
                createUser={createUser}
                onCancel={onCancel}
                onSubmit={onUserSubmit}
                fields={userFields}
                history={history}
            />
            <Layout className={'contentLayout'}>
                <h2>
                    {t('Hosts')}
                </h2>
                <SitesList
                    createUser={createUser}
                    setCreateUser={setCreateUser}
                    updateUser={updateUser}
                    setUpdateUser={setUpdateUser}
                    fields={userFields}
                    setFields={setUserFields}
                    onOpenModal={onOpenUserModal}
                    onCancel={onCancel}
                />
            </Layout>
        </>
    )
})
