export const COLORS = {
    BLACK: 'black',
    GREEN: 'green',
    RED: 'red',
    BLUE: 'blue',
    YELLOW: 'yellow',
    LAVENDER: 'lavender',
    GRAY: 'gray',
    WHITE: 'white',
    ORANGE_1: '#ffa500'
}
