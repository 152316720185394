import React, {useEffect, useState} from 'react'
import Button from "antd/es/button";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Table from "antd/es/table";
import Badge from "antd/es/badge";
import {COLORS} from "../../constants/colors";
// import {ChangePasswordModal} from "./ChangePasswordModal";
import Popconfirm from "antd/es/popconfirm";
import {Content} from "antd/es/layout/layout";
import Tooltip from "antd/es/tooltip";
import {StopFilled, ZhihuSquareFilled} from "@ant-design/icons";
import CheckCircleFilled from "@ant-design/icons/CheckCircleFilled";
import {Link} from "react-router-dom";
import Spin from "antd/es/spin";
import {autorun} from "mobx";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { observer } from 'mobx-react-lite';

import {hostsModel} from "../../model/hostsModel.js";
import {domainModel} from "../../model/domainModel.js";
import {classModel} from "../../model/classModel.js";
import {countriesModel} from "../../model/countriesModel.js";
import {langModel} from "../../model/langModel.js";
import {brandsModel} from "../../model/brandsModel.js";
import {profileModel} from "../../model/profleModel.js";

import {hostCategoryModel} from "../../model/hostCategoryModel.js";
import {sitesModel} from "../../model/sitesModel.js";
import Select from "antd/es/select";
import {useNavigate} from "react-router-dom";

import Col from "antd/es/col";
import Form from "antd/es/form";
import Input from "antd/es/input/index.js";
import Row from "antd/es/row";
// import {iabModel} from "../../model/iabModel.js";
import {localstorageModel} from "../../model/localstorageModel.js";
import {useTranslation} from "react-i18next";

const hostStyle = {
    borderBottom:  '1px solid lightgray', width: 'auto'
}

export const SitesList = observer((props) => {
    const {
        onOpenModal,
    } = props

    const {t} = useTranslation()

    useEffect(
        // FIXME useEffect GO TO MODEL!! OR CONTAINER
        () =>
            autorun(() => {
                classModel.get()
                hostCategoryModel.get()
                hostsModel.get()
                domainModel.get()
                countriesModel.get()
                langModel.get()
                brandsModel.get()
                profileModel.get()
                sitesModel.get()
            }),
        [],
    )

    const deleteUser = (id, name) => {
        hostCategoryModel.delete(id, name)
    }
    // FIXME refact to useLocate
    const currentPage = parseInt(window.location.href?.split('page=')[1]?.split('&')[0] ?? 1)
    const currentPageSize = parseInt(window.location.href?.split('limit=')[1] ?? 20)

    const navigate = useNavigate()

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const dictionary = hostsModel.dictionary


    const current_lang = localstorageModel.language

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };



    const data = hostCategoryModel.data.sites



    const columns = [
        {
            title: t('Name'),
            width: '15%',
            dataIndex: 'name',
        },
        {
            title: t('IP Addresses'),
            dataIndex: 'ip_addresses',
            width: '20%',
            render:  (items) => (
                <>
                    {items.map((item) => {
                        return (
                            <p>
                                {item}
                            </p>
                        );
                    })}
                </>
            ),
        },
        {
            title: t('Host Taxonomy'),
            dataIndex: 'host_taxonomy_ids',
            width: '40%',
            render:  (id) => (
                <>
                    {
                        id?.map(item => {
                            return (
                                <p style={hostStyle}>
                                    {
                                        dictionary[current_lang] ? dictionary[current_lang][item] : '-'
                                    }
                                </p>
                            )
                        })
                    }
                </>
            ),
        },
        {
            title: t('Classes'),
            dataIndex: 'class_manual_ids',
            width: '20%',
            render:  (classes) => (
                <>
                    {classes?.map((el) => {
                        return (
                            <p>
                                {classModel.dictionary[el]}
                            </p>
                        );
                    })}
                </>
            ),
        },

        {
            title: t('Actions'),
            align: 'center',
            width: '10%',
            className: 'table-operations',
            render: user =>
                <Spin spinning={user.status === 'processing'}>
                    <Button.Group style={{display: 'flex', flexWrap: 'wrap', width: '46px', justifyContent: 'center', margin: '0 auto'}}>
                            <Tooltip placement="topLeft" title={t('Update Host')}>
                                {/*<Link >*/}
                                    <Button type="default" onClick={() => onOpenModal('update', user)}>
                                        <EditOutlined/>
                                    </Button>
                                {/*</Link>*/}
                            </Tooltip>
                        <Popconfirm
                            title={t(`Are you sure to delete this Host?`)}
                            onConfirm={() => deleteUser(user.id, user.name)}
                            okText={t("Yes")}
                            cancelText={t("No")}
                        >
                            <Tooltip placement="topLeft" title={t('Delete this host')}>
                                <Button type="default" style={{marginLeft: 0}}>
                                    <DeleteOutlined />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </Button.Group>
                </Spin>
        }
    ];


    const onChangePage = (page, pageSize = 20) => {
        navigate(
            `/hosts?${window.location.href.includes('?name=') ?
                'name=' + window.location.href?.split('?name=')[1]?.split('&')[0]+'&' : ''}page=${page}&limit=${pageSize}`
        )
        const filterName = window.location.href?.split('?name=')[1]?.split('&')[0]
        hostCategoryModel.changePage(page, pageSize, filterName)
    }


    const styleSelect = {
        width: '100%'
    }

    const onFilter = (value) => {
        if (value !== undefined) {
            navigate(
                `/hosts?${value.name !== '' ? 'name=' + value.name + '&': ''}page=${currentPage}&limit=${currentPageSize}`
            )
        }
        hostCategoryModel.changePage(currentPage, currentPageSize, value.name)
    }


    const flex = {
        display: 'flex'
    }




    return (
        <Content>
            <Form
                name="filterSites"
                id="filterSites"
                onFinish={onFilter}
            >
                <Row justify='flexStart'>
                    <Form.Item
                        label={t('Name')}
                        name="name"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '20%',
                            marginRight: '10px'
                        }}
                    >
                        <Input
                            placeholder={t("Filter Name")}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Col span={6}>
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                {t('Filter')}
                            </Button>
                        </Col>
                    </Form.Item>
                </Row>
            </Form>


            <br/>

            <Table
                bordered
                id='usersListTable'
                size={'small'}
                dataSource={data}
                columns={columns}
                // rowSelection={rowSelection}
                onChange={pagination => onChangePage(pagination.current, pagination.pageSize)}
                pagination={{
                    position: ['bottomCenter'],
                    current: currentPage,
                    total: hostCategoryModel.data.count,
                    defaultPageSize: currentPageSize,
                    showSizeChanger: true,
                    pageSizeOptions: [20, 50, 100, 200]
                }}
            />

        </Content>
    )
});

