import React from 'react'
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import {useTranslation} from "react-i18next";

// const layout = {
// 	labelCol: {span: 6},
// 	wrapperCol: {span: 14},
// };


export const ChangePasswordModal = (props) => {

    const {changePasswordModal, onCancel, onSubmit} = props

    const {t} = useTranslation()

    const [form] = Form.useForm();

    return (
        <Modal title={`${t('Change password for ')} ${changePasswordModal && (changePasswordModal?.full_name ?? changePasswordModal?.username)}`}
               visible={changePasswordModal}
               onCancel={() => {
                   onCancel()
                   form.resetFields()
               }}
               destroyOnClose={true}
               footer={[
                   <div key={1}>
                       <Button onClick={() => {
                           onCancel()
                           form.resetFields()
                       }}>
                           {t('Cancel')}
                       </Button>
                       <Button form="changePasswordForm" type={"primary"} htmlType="submit">
                           {t('Save')}
                       </Button>
                   </div>
               ]}
               width={600}>
            <Form
                form={form}
                id="changePasswordForm"
                layout={'vertical'}
                name="changePasswordForm"
                onFinish={(v) => {
                    onSubmit(v, changePasswordModal.id)
                    form.resetFields()
                }}
            >
                <Form.Item
                    name="password"
                    label={t("Password")}
                    rules={[
                        {
                            required: true,
                            message: t('Please input your password!'),
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label={t("Confirm Password")}
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: t('Please confirm your password!'),
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
            </Form>
        </Modal>
    )
}
