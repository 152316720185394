import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import I18NextHttpBackend from 'i18next-http-backend'
// import eng from './locales/en/translation.json'
// import rus from './locales/ru/translation.json'
// import kaz from './locales/kz/translation.json'

// const resources = {
//     en: {
//         translation: eng
//     },
//     ru: {
//         translation: rus
//     },
//     kz: {
//         translation: kaz
//     },
// }

// i18next
//     .init({
        // ...
        // debug: true,

    // });


// await i18next
//     .use(initReactI18next) // passes i18n down to react-i18next
//     .init({
//         resources,
//         lng: localStorage.getItem("language"), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
//         // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
//         // if you're using a language detector, do not define the lng option
//
//         interpolation: {
//             escapeValue: false // react already safes from xss
//         }
//     });


await i18next
    .use(I18NextHttpBackend)
    .use(initReactI18next)
    .init({
        // resources,
        lng: localStorage.getItem("language"),
        debug: true,
        // debug
        fallbackLng: 'en',
        interpolation: {escapeValue: false},
        ns: 'translation',
        defaultNS: 'translation',
        backend: {
            // loadPath: `${window.location.origin}/public/locales/{{lng}}/{{ns}}.json`
            loadPath: `${window.location.origin}/locales/{{lng}}/{{ns}}.json`
        }
    })

export default i18next
