import React, {useEffect, useState} from "react";
import Table from "antd/es/table";
import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Popconfirm from "antd/es/popconfirm";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import {Link} from "react-router-dom";
import Spin from "antd/es/spin";
import {DeleteModalComponent} from "../../components/DeleteModalComponent.jsx";
import CopyOutlined from "@ant-design/icons/CopyOutlined";
import FileOutlined from "@ant-design/icons/FileOutlined";
import TagsOutlined from "@ant-design/icons/TagsOutlined";
import {relationsParse} from "../../tools/termsTools.jsx";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import {sitesModel} from "../../model/sitesModel.js";
import { observer } from 'mobx-react-lite';

import {iabModel} from "../../model/iabModel.js";
import {localstorageModel} from "../../model/localstorageModel.js";
export const IabList = observer((props) => {

    const {
        terms,
        users,
        onDeleteTerm,
        onOpenTermModal,
        CRUDStatus,
        goToTermGroupPage,
        relationTypes,
        files,
        dispatch,
        currentUser,
        match,
        history,
        showDeleteModal,
        onOpenSectionModal,
        deleteModal,
        setCurrentIabForDelete
    } = props

    // const {view, catId, term, termId, action} = match.params

    const [uploadModalInList, setUploadModalInList] = useState({
        visible: false,
        id: null
    });

    const dictionary = iabModel.dictionary[localstorageModel.language]
    const data_lang = iabModel.data_lang


    const showModal = (id) => {
        setUploadModalInList({
            visible: true,
            id: id
        });


        if (id) {
            const payload = {
                type: 'terms',
                id: id
            }
            dispatch(uploadFileActions.getFileFetch(payload))
        }
    };

    const onDeleteIab = (payload) => {
        deleteModal(true)
        setCurrentIabForDelete(payload)

    }

    const hideModal = () => {
        setUploadModalInList({
            visible: false,
            id: null
        });
    };

    const onCancel = () => {
        setUploadModalInList({
            visible: false,
            id: null
        });
    }

    const onDelete = (id) => {
        iabModel.delete(id)
    }


    const columns = [
        {
            title: 'IAB id',
            dataIndex: 'iab_id',
            render: (_, record) => (
                <>
                    <span>{record.id ? record.id : '-'}</span>
                </>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (_, term) =>
              <>
                   {/*   <span>{dictionary[term?.id]}</span>*/}


                <Tooltip placement="topLeft" title={term.description}>
                    <>
                        <div style={{display: 'flex', gap: 10}}>

							<span>

								{term.children?.length ?
                                    <CopyOutlined
                                        style={{cursor: "pointer"}}
                                        onClick={() => term.children?.length && goToTermGroupPage(term.id)}
                                    />
                                    :
                                    <FileOutlined />}</span>

                            <div style={{display: 'flex', flexDirection: 'column'}}>

							<span style={{display: 'flex', alignItems: 'center', gap: 10}}>

								<p style={{margin: 0}}>{term['name_'+localstorageModel.language]}</p>

								<Tooltip placement="topLeft" title={
                                    // term.categories.map(category => category.name).join(', ')
                                    "test FIXME"
                                }>
									<TagsOutlined style={{cursor: "pointer"}} />
								</Tooltip>

							</span>

                                <span style={{fontSize: 10}}>
								{term.short_description}
							</span>

                            </div>
                        </div>
                    </>
                </Tooltip>
              </>
        },
        {
            title: 'Parent',
            dataIndex: 'parent_id',
            /*render: iab_id => iabModel.dictionary[iab_id]*/
            render: (_, record) =>

                <>
                    <p>{record?.parent_id !== null ? dictionary[record?.parent_id] : '-'}</p>

                </>
                // <>
                //
                //         <span>   {}    </span>
                //
                // </>


        },
        {
            title: 'Description / Created by / Created at',
            dataIndex: 'responsibility_on',
            render: (_, record) =>
                <>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        <span>{record.description ? record.description : '-'}</span>
                        <span>{record.updated_by ? record.updated_by : record.created_by}</span>
                        <span>
                            {
                                record.updated_at
                                ?
                                record?.updated_at?.split('T')[0]  + ' ' + record?.updated_at?.split('T')[1].split('.')[0]
                            :
                            record?.created_at?.split('T')[0] + ' ' + record?.created_at?.split('T')[1].split('.')[0]}
                        </span>
                    </div>
                </>

        },
        // {
        //     title: 'Relations',
        //     dataIndex: 'relations',
        //     render: (relation, entity) =>  relationsParse(relation, relationTypes).map(item => {
        //         return <>
        //             {item?.relations.length > 0 && <b>{item.name}</b>}
        //             <div>
        //                 {
        //                     item?.relations.map((relationEntity,index) => {
        //                         return <Link to={'#'}> {`${entity.id === relationEntity.left_id
        //
        //                             ? relationEntity.right_name : relationEntity.left_name}${item.relations.length -1 === index ?  "" : ','}`}
        //                         </Link>
        //                     })
        //                 }
        //             </div>
        //         </>
        //     })
        // },
        {
            key: 'operations',
            align: 'center',
            render: (_, term) => (
                <Spin spinning={term?.status === 'processing'}>
                    <Button.Group>
                        <Button.Group style={{display: 'flex', flexDirection: 'column'}}>
                                <Tooltip placement="topLeft" title={'Update IAB'}>
                                    <Link to={'#'}>
                                        <Button type="default" onClick={() => onOpenSectionModal('update', term)}>
                                            <EditOutlined/>
                                        </Button>
                                    </Link>
                                </Tooltip>
                                {/*<Tooltip placement="topLeft" title={`View ${term?.children ? 'group' : 'term'}`}>*/}
                                {/*    <Button*/}
                                {/*        type="default"*/}
                                {/*        onClick={() =>*/}
                                {/*            history.push(`/terms/category/${catId}/${term?.children*/}
                                {/*                ? 'group'*/}
                                {/*                : 'term'}/${term.id}/graph`)}*/}
                                {/*    >*/}
                                {/*        <EyeOutlined/>*/}
                                {/*    </Button>*/}
                                {/*</Tooltip>*/}
                                {/*<Tooltip placement="topLeft" title={'Upload Files'}>*/}
                                {/*    <Button type="default" onClick={() => showModal(term.id)} style={{marginLeft: 0}}>*/}
                                {/*        <FileOutlined/>*/}
                                {/*    </Button>*/}
                                {/*</Tooltip>*/}
                                <Popconfirm
                                    title="Are you sure to delete this IAB?"
                                    /* onConfirm={() => onDelete(term.id)}*/
                                    // onConfirm={deleteModal}
                                    onConfirm={() => onDeleteIab(term)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Tooltip placement="topLeft" title={'Delete this IAB'}>
                                        <Button type="primary" danger style={{marginLeft: 0}}
                                        >
                                            <DeleteOutlined/>
                                        </Button>
                                    </Tooltip>
                                </Popconfirm>



                        </Button.Group>
                    </Button.Group>
                </Spin>
            ),
            width: 46
        },
    ];

    // const data = iabModel.data
    const data = iabModel.current_category != null ? iabModel.data.filter(
        item => item.parent_id === iabModel.current_category.id
    ) : iabModel.data
 //






    return (
        <>
            <Table
                columns={columns}
                bordered
                // dataSource={terms}
                dataSource={data_lang}
                size={'small'}
                showExpandColumn={false}
                style={{marginTop: '0.5em'}}
                rowKey="id"
                pagination={{
                    position: ['bottomCenter'],
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    pageSizeOptions: [20, 50, 100, 200]
                }}
            />
        </>

    )
})
