import React, {useState} from "react";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Select from "antd/es/select";
import Table from "antd/es/table";
import Button from "antd/es/button";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import Popconfirm from "antd/es/popconfirm";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import {v4 as uuidv4} from "uuid";
import TreeSelect from "antd/es/tree-select";
import Checkbox from "antd/es/checkbox/Checkbox";

const {Option} = Select

export const RelationsComponent = ({
                                       types,
                                       relationForm,
                                       relations,
                                       setRelations,
                                       relatedTerms
                                   }) => {

    const [leftSide, setLeftSide] = useState(false);

    const columns = [
        {
            title: 'Left side',
            dataIndex: 'left_side',
            align: 'center',
            render: side => <Checkbox checked={side} disabled />
        },
        {
            title: 'Type',
            dataIndex: 'relation_type_id',
            align: 'center',
            render: (relation_type_id, relation) => <Select
                onChange={(v) => onChangeRelation(relation.key, 'relation_type_id', v)}
                value={relation_type_id}
                style={{width: 300}}
            >
                {types.map(t => <Option value={t.id} key={`type-${t.id}`}>{t.name}</Option>)}
            </Select>,
        },
        {
            title: 'Related to',
            dataIndex: 'right_id' || 'left_id',
            align: 'center',
            render: (_, relation) =>
                <TreeSelect
                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                    treeData={relatedTerms}
                    style={{width: 300}}
                    treeDefaultExpandAll
                    fieldnames={{label: 'name', value: 'id', children: 'children'}}
                    onChange={(v) => onChangeRelation(relation.key, `${relation.left_side ? 'left_id' : 'right_id'}`, v)}
                    defaultValue={relation.left_side ? relation.left_name ?? relation.left_id : relation.right_name ?? relation.right_id}
                />
        },
        {
            key: 'operations',
            align: 'center',
            removed: true,
            width: 50,
            render: (relation) => (
                <Button.Group>
                    <Popconfirm
                        title={`Are you sure to delete this relation?`}
                        onConfirm={() => onDeleteRelation(relation.key)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger>
                            <DeleteOutlined/>
                        </Button>
                    </Popconfirm>
                </Button.Group>
            ),
        }
    ]

    const onAddRelation = (v) => {
        setRelations(prev => [...prev, {key: uuidv4(), ...v}])

        relationForm.resetFields()
        setLeftSide(false)
    }

    const onChangeRelation = (key, field, value) => {
        setRelations(prev => prev.map(item => {
            if (item.key === key) {
                return {...item, [field]: value}
            } else {
                return item
            }
        }))
    }

    const onDeleteRelation = (key) => {
        setRelations(prev => [...prev.filter(item => item.key !== key)])
    }

    return (
        <Card>
            <Form
                style={{marginBottom: 15}}
                id={'relationsForm'}
                layout={'inline'}
                form={relationForm}
                name={'relationsForm'}
                onFinish={onAddRelation}
            >
                <Form.Item
                    label={"Left side"}
                    name={'left_side'}
                    style={{marginLeft: 10}}
                    valuePropName="checked"
                >
                    <Checkbox value={leftSide} checked={false} onChange={() => setLeftSide(prev => !prev)} />
                </Form.Item>
                <Form.Item
                    label={"Type"}
                    name={'relation_type_id'}
                    style={{width: "30%"}}
                    rules={[{required: true, message: 'Select type!'}]}
                >
                    <Select>
                        {types?.map(type => <Option value={type.id} key={`type-${type.id}`}>{type.name}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={"Related to"}
                    name={leftSide ? 'left_id' : 'right_id'}
                    style={{width: "30%"}}
                    rules={[{required: true, message: 'Select Term!'}]}
                >
                    <TreeSelect
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        showSearch
                        treeData={relatedTerms}
                        filterTreeNode={(search, item) => {
                            return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                        }}
                        fieldnames={{label: 'name', value: 'id', children: 'children'}}
                    />
                </Form.Item>
                <Button
                    type="primary"
                    form={`relationsForm`}
                    htmlType="submit"
                    style={{width: '80px', marginLeft: 'auto'}}>
                    Add <PlusOutlined/>
                </Button>
            </Form>
            <Table
                dataSource={relations}
                columns={columns}
                bordered
                size={'small'}
                style={{marginTop: '0.5em'}}
                pagination={{
                    position: ['bottomCenter'],
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    pageSizeOptions: [20, 50, 100, 200]
                }}
            />
        </Card>
    )
}
