import moment from "moment";

export const changeFormFieldsData = (array, object) => {
    let newArr = []

    for (let i in array) {
        for (let key in object) {
            if (array[i].name === key) {
                newArr.push({
                    name: array[i].name,
                    value: object[key]
                });
            }
        }
    }
    return newArr
}

// for users/groups/departments limits
export const changeFormFieldsDataWithLimits = (array, object) => {

    let newArr = []
    for (let i in array) {
        for (let key in object) {
            if (array[i].name === key) {
                if (array[i].name.includes('limit')) {
                    newArr.push({
                        name: array[i].name,
                        value: array[i].value
                    });
                } else {
                    newArr.push({
                        name: array[i].name,
                        value: object[key]
                    });
                }
            }
        }
    }
    return newArr
}



export const makeInitialFields = (obj) => {
    const result = []
    Object.entries(obj).forEach(([key, value]) => result.push({
        name: key,
        value: /date/.test(key) ? moment(value) : value
    }));
    return result
}
