import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";
import cookies from "react-cookies";



export const auditModel = makeAutoObservable({
    data: [],
    get: async () => {
        try {
            const audit = await instance().get(`/audit/?page=1&limit=20`).then(res => res.data)
            auditModel.data = audit.data
        } catch (e) {
            console.log('usersModel CATCH', e)
        }
    },
    changePage: async (page, limit, filter) => {
        try {
            let final = ''


            if (filter?.username) {
                final = final + `userName=${filter.username}&`
            }
            if (filter?.action) {
                final = final + `action=${filter.action}&`
            }
            if (filter?.object_name) {
                final = final + `objectName=${filter.object_name}&`
            }
            if (filter?.object_type) {
                final = final + `objectType=${filter.object_type}&`
            }

            if (filter?.fromDate) {
                final = final + `fromDate=${filter.fromDate}&`
            }
            if (filter?.toDate) {
                final = final + `toDate=${filter.toDate}&`
            }



            const audit = await instance().get(`/audit/?${final}page=${page}&limit=${limit}`).then(res => res.data)
            auditModel.data = audit.data
        } catch (e) {
            console.log('usersModel CATCH', e)
        }
    },



});
