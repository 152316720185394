import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";

export const classModel = makeAutoObservable({
   data: [],
   dictionary: [],
   status: false,
   current: null,
   error: null,
   classSummary: {},
   classSummaryArr: [],
   currentModel: [],
   currentManual: [],
   currentExlude: [],
   currentSite: [],
   calcSummary: (value) => {
      value.map(item => {
         if (classModel.classSummary[item]) {
            delete classModel.classSummary[item]
         }
      })
      classModel.classSummaryArr = Object.values(classModel.classSummary).map(
          item => {return classModel.dictionary[item]}
      )
   },
   autoSummary: () => {
      classModel.classSummaryArr = []
      classModel.classSummary = {}

      // const current_model_ids = [
      //    1, 2, 3, 4, 5
      // ]
      // FIXME ^
      // FIXME ADD REAL DATA
      // FIXME >
      // const current_model_ids = currentSite.class_model_ids ? currentSite.class_model_ids : []
      const current_model_ids = classModel.currentSite.class_model_ids

      classModel.currentModel = current_model_ids

      classModel.currentManual?.map(item => {
         if (!classModel.classSummary[item]) {
            classModel.classSummary[item] = item
         }
      })

      classModel.currentModel?.map(item => {
         if (!classModel.classSummary[item]) {
            classModel.classSummary[item] = item
         }
      })

      classModel.currentExlude?.map(item => {
         if (classModel.classSummary[item]) {
            delete classModel.classSummary[item]
         }
      })

      classModel.classSummaryArr = Object.values(classModel.classSummary)?.map(
          item => {return classModel.dictionary[item]}
      )
   },
   addManual: (value) => {
      classModel.currentManual = value
      classModel.autoSummary()
   },
   addExclude: (value) => {
     classModel.currentExlude = value
      classModel.autoSummary()
   },
   open: (currentSite) => {
      // const current_model_ids = [
      //    1, 2, 3, 4, 5
      // ]
      // FIXME ^
      // FIXME ADD REAL DATA
      // FIXME >
      classModel.currentSite = currentSite
      const current_model_ids = currentSite.class_model_ids ? currentSite.class_model_ids : []

      classModel.currentManual = currentSite?.class_manual_ids
      classModel.currentModel = current_model_ids
      classModel.autoSummary()
   },

   get: async () => {
      try {
         const payload = await instance().get('/classes/').then(res => res.data)
         classModel.data = payload.data
         classModel.dictionary = Object.fromEntries(payload.data.map(
             item => [item.id, item.name])
         )
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   create: async (payload) => {
      try {
        const newObj =  await instance().post('/classes/', payload).then(res => res.data)
         classModel.status = 'create'
         classModel.current = newObj.data.username
         await classModel.get()
         // check await? FIXME usersModel.getUsers()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   delete: async (id, username) => {
      try {
         await instance().delete(`/classes/purge/${id}`).then(res => res.data)
         classModel.status = 'delete'
         classModel.current = username
         await classModel.get()

      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },
   update: async (payload, object) => {
      try {
         await instance().put(`/classes/${object.id}`, payload).then(res => res.data)
         await classModel.get()
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },

});
