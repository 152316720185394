import React, { useState, useRef, useEffect } from 'react';
import Input from 'antd/es/input';

export const PhoneInput = ({ onChange, ...restProps }) => {
    const inputRef = useRef();

    const onChangeHandle = () => {
        if (!inputRef.current?.input?.value) {
            return;
        }

        const value = inputRef.current?.input?.value
            .replace(/\D/g, '')
            .match(/(\d{1,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

        if (!value) {
            onChange(null);
            return;
        }

        const formattedValue = getFormattedPhoneNumber(value);
        onChange(formattedValue);
    };

    return <Input ref={inputRef} onChange={onChangeHandle} {...restProps} />;
};

const getFormattedPhoneNumber = (matchedValue) => {
    let number = '';
    if (matchedValue[1]) {
        number += `+${matchedValue[1]}`;
    }
    if (matchedValue[2]) {
        number += ` (${matchedValue[2]}`;
    }
    if (matchedValue[3]) {
        number += `) ${matchedValue[3]}`;
    }
    if (matchedValue[4]) {
        number += `-${matchedValue[4]}`;
    }
    if (matchedValue[5]) {
        number += `-${matchedValue[5]}`;
    }
    return number;
};
