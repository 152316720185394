import React, {useState} from "react";
import Table from "antd/es/table";
import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Popconfirm from "antd/es/popconfirm";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import {Link} from "react-router-dom";
import Spin from "antd/es/spin";
import CopyOutlined from "@ant-design/icons/CopyOutlined";
import FileOutlined from "@ant-design/icons/FileOutlined";
import TagsOutlined from "@ant-design/icons/TagsOutlined";
import {relationsParse} from "../../tools/termsTools.jsx";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import {sitesModel} from "../../model/sitesModel.js";
import { observer } from 'mobx-react-lite';

import {interestModel} from "../../model/interestModel";
import {localstorageModel} from "../../model/localstorageModel.js";
import {useTranslation} from "react-i18next";

export const InterestList = observer((props) => {

    const {
        terms,
        users,
        onDeleteTerm,
        onOpenTermModal,
        CRUDStatus,
        goToTermGroupPage,
        relationTypes,
        files,
        dispatch,
        currentUser,
        match,
        history,
        onOpenSectionModal
    } = props

    // const {view, catId, term, termId, action} = match.params

    const [uploadModalInList, setUploadModalInList] = useState({
        visible: false,
        id: null
    });

    const {t} = useTranslation()

    const showModal = (id) => {
        setUploadModalInList({
            visible: true,
            id: id
        });


        if (id) {
            const payload = {
                type: 'terms',
                id: id
            }
            dispatch(uploadFileActions.getFileFetch(payload))
        }
    };

    const hideModal = () => {
        setUploadModalInList({
            visible: false,
            id: null
        });
    };

    const onCancel = () => {
        setUploadModalInList({
            visible: false,
            id: null
        });
    }

    const onDelete = (id) => {
        interestModel.delete(id)
    }

    const current_lang = localstorageModel.language

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name',
            render: (_, term) =>
                <Tooltip placement="topLeft" title={term.description}>
                    <>
                        <div style={{display: 'flex', gap: 10}}>

							<span>

								{term.children?.length ?
                                    <CopyOutlined
                                        style={{cursor: "pointer"}}
                                        onClick={() => term.children?.length && goToTermGroupPage(term.id)}
                                    />
                                    :
                                    <FileOutlined />}</span>

                            <div style={{display: 'flex', flexDirection: 'column'}}>

							<span style={{display: 'flex', alignItems: 'center', gap: 10}}>

								<p style={{margin: 0}}>{term.name}</p>

								<Tooltip placement="topLeft" title={
                                    // term.categories.map(category => category.name).join(', ')
                                    "test FIXME"
                                }>
									<TagsOutlined style={{cursor: "pointer"}} />
								</Tooltip>

							</span>

                                <span style={{fontSize: 10}}>
								{term.short_description}
							</span>

                            </div>
                        </div>
                    </>
                </Tooltip>
        },
        {
            title: t('Description / Created by / Created at'),
            dataIndex: 'responsibility_on',
            render: (_, record) =>
                <>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                        <span>{current_lang === 'ru' ? record.description :
                            record[`description_${current_lang}`] ? record[`description_${current_lang}`] : '-'}</span>
                        <span>{record.updated_by ? record.updated_by : record.created_by}</span>
                        <span>
                            {
                                record.updated_at
                                    ?
                                    record?.updated_at?.split('T')[0]  + ' ' + record?.updated_at?.split('T')[1].split('.')[0]
                                    :
                                    record?.created_at?.split('T')[0] + ' ' + record?.created_at?.split('T')[1].split('.')[0]}
                        </span>
                    </div>
                </>

        },
        {
            key: t('operations'),
            align: 'center',
            render: (_, term) => (
                <Spin spinning={term?.status === 'processing'}>
                    <Button.Group>
                        <Button.Group style={{display: 'flex', flexDirection: 'column'}}>
                                <Tooltip placement="topLeft" title={t('Update Interest')}>
                                    <Link to={'#'}>
                                        <Button type="default" onClick={() => onOpenSectionModal('update', term)}>
                                        {/*<Button type="default" onClick={() => console.log('update', term)}>*/}
                                            <EditOutlined/>
                                        </Button>
                                    </Link>
                                </Tooltip>
                                {/*<Tooltip placement="topLeft" title={`View ${term?.children ? 'group' : 'term'}`}>*/}
                                {/*    <Button*/}
                                {/*        type="default"*/}
                                {/*        onClick={() =>*/}
                                {/*            history.push(`/terms/category/${catId}/${term?.children*/}
                                {/*                ? 'group'*/}
                                {/*                : 'term'}/${term.id}/graph`)}*/}
                                {/*    >*/}
                                {/*        <EyeOutlined/>*/}
                                {/*    </Button>*/}
                                {/*</Tooltip>*/}
                                {/*<Tooltip placement="topLeft" title={'Upload Files'}>*/}
                                {/*    <Button type="default" onClick={() => showModal(term.id)} style={{marginLeft: 0}}>*/}
                                {/*        <FileOutlined/>*/}
                                {/*    </Button>*/}
                                {/*</Tooltip>*/}
                                <Popconfirm
                                    title={t("Are you sure to delete this Interest?")}
                                    onConfirm={() => onDelete(term.id)}
                                    okText={t("Yes")}
                                    cancelText={t("No")}
                                >
                                    <Tooltip placement="topLeft" title={t('Delete this Interest')}>
                                        <Button type="primary" danger style={{marginLeft: 0}}>
                                            <DeleteOutlined/>
                                        </Button>
                                    </Tooltip>
                                </Popconfirm>
                        </Button.Group>
                    </Button.Group>
                </Spin>
            ),
            width: 46
        },
    ];
    const temp = interestModel.data

    const data = interestModel.current_category != null ? interestModel.data.filter(
        item => item.parent_id === interestModel.current_category.id
    ) : interestModel.data


    return (
        <>

            <Table
                columns={columns}
                bordered
                // dataSource={terms}
                dataSource={data}
                size={'small'}
                showExpandColumn={false}
                style={{marginTop: '0.5em'}}
                rowKey="id"
                pagination={{
                    position: ['bottomCenter'],
                    defaultPageSize: 20,
                    showSizeChanger: true,
                    pageSizeOptions: [20, 50, 100, 200]
                }}
            />
        </>

    )
})
