import React, {useEffect} from 'react'
import Button from "antd/es/button";
import Modal from "antd/es/modal";
import Form from "antd/es/form";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Tabs from "antd/es/tabs";
import './UsersGroupsModalsStyles.css';
import {useTranslation} from "react-i18next";

const {TabPane} = Tabs;

const {Option} = Select


const layout = {
    layout: 'vertical'
};


export const CreateUpdateUsersModal = (props) => {

    const {
        updateUser,
        createUser,
        onCancel,
        onSubmit,
        fields,
        users,
        history,
        pathAction,
        domains
    } = props

    console.log('fields', fields)




    const {tabName} = 'test';

    const checkName = (_, value) => {
        const validName = new RegExp('^[_a-z][_a-z0-9]+$')
        if (value && !validName.test(value)) {
            return Promise.reject(new Error('Login could contain only letters, numbers, - and _'));
        }
        if (value?.length <= 3) {
            return Promise.reject(new Error('Enter minimum 3 letters!'));
        }
        if (value && users.findIndex(item => item.username === value) !== -1) {
            UniqueValueNotification('login')
            return Promise.reject(new Error('Enter unique login!'));
        }
        return Promise.resolve()
    }


    const onChangeTab = (tabName) => {
        if (updateUser) {
            history.push(`/users/users/${updateUser.id}/updateUser`)
        }
        if (createUser) {
            history.push(`/users/${pathAction}`)
        }
    };

    const style = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
    }

    const domainsOption = domains.map(item => {
        return {
            label: item.name,
            value: item.id,
        }
    })
    const {t} = useTranslation()


    const tabActiveKey = createUser
        ? tabName
        : updateUser;


    return (
        <Modal title={updateUser ? `${t('Update Country')} - ${updateUser?.name}` : t('Create Country')}
               wrapClassName='createUpdateUsersModal'
               visible={updateUser || createUser}
               onCancel={onCancel}
               style={{top: 10}}
               destroyOnClose={true}
               footer={[
                   <div key={1} style={{padding: '0 8px'}}>
                       <Button onClick={onCancel}>
                           {t('Cancel')}
                       </Button>
                       <Button form="userForm" type={"primary"} htmlType="submit">
                           {t('Save')}
                       </Button>
                   </div>
               ]}
               width={900}>
                    <Form
                        id="userForm"
                        {...layout}
                        name="userForm"
                        fields={fields}
                        style={style}
                        onFinish={onSubmit}
                    >
                        <Row justify={'space-between'}>
                            <Col>
                                <Form.Item
                                    label={t("Name")}
                                    name="name"
                                    rules={[
                                        {required: true, message: 'Enter name!'},

                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={t("Name EN")}
                                    name="name_en"
                                    rules={[
                                        {required: true, message: 'Enter name!'},

                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={t("Name KZ")}
                                    name="name_kz"
                                    rules={[
                                        {required: true, message: 'Enter name!'},

                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label={t("Domain zone")}
                                    name="domain_zone_id"
                                    rules={[
                                        {required: true, message: 'Enter name!'},
                                    ]}
                                >
                                    <Select
                                        style={{
                                            width: 120,
                                        }}
                                        options={domainsOption}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>


                    </Form>
        </Modal>



    )
}
