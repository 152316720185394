// import {TermsList} from "./components/TermsList/TermsList";
// import {SitesCategoryList} from "./SitesCategoryList.jsx";
import React, {useEffect, useState} from "react";
import Card from "antd/es/card";
import Tree from "antd/es/tree";
import DownOutlined from "@ant-design/icons/DownOutlined";
import Row from "antd/es/row";
import Col from "antd/es/col";
import {addKeysToArray} from "../../tools/addKeysToArray.jsx";
import Button from "antd/es/button";
import PlusSquareOutlined from "@ant-design/icons/PlusSquareOutlined";
import EditOutlined from "@ant-design/icons/EditOutlined";
import Popconfirm from "antd/es/popconfirm";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
// import {SectionModal} from "../../components/SectionModalComponent.jsx";
import {SectionModal} from "./SectionModal"
import {changeFormFieldsData} from "../../tools/changeFormFieldsData.jsx";
import Form from "antd/es/form";
import {v4 as uuidv4} from "uuid";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";
import PlusCircleOutlined from "@ant-design/icons/PlusCircleOutlined";
import {urlToObject} from "../../tools/urlToObject.jsx";
import {SideBarComponent} from "../SideBar/SideBarComponent.jsx";
import Layout from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import Dropdown from 'antd/es/dropdown';
import Menu from 'antd/es/menu';
import TableOutlined from "@ant-design/icons/TableOutlined";
import UndoOutlined from "@ant-design/icons/UndoOutlined";
import Spin from "antd/es/spin";
// import {SitesCategoryGroupList} from "./SitesCategoryGroupList.jsx";
// import {IabGroupList} from "./IabGroupList.jsx";
import RollbackOutlined from "@ant-design/icons/RollbackOutlined";
import FileOutlined from "@ant-design/icons/FileOutlined";
// import {IabModal} from "./IabModal.jsx";
import {sitesModel} from "../../model/sitesModel.js";

import {autorun} from "mobx";
import { observer } from 'mobx-react-lite';
// import {IabList} from "./IabList.jsx";
import {InterestModal} from "./InterestModal.jsx";

import {InterestList} from "./InterestList.jsx";
import {InterestGroupList} from "./InterestGroupList.jsx";

import {interestModel} from "../../model/interestModel";
import {profileModel} from "../../model/profleModel.js";
import {useTranslation} from "react-i18next";




const initialSectionFields = [
    {
        name: 'name',
        value: ''
    },
    {
        name: 'name_en',
        value: ''
    },
    {
        name: 'name_kz',
        value: ''
    },
    {
        name: 'description',
        value: ''
    },
    {
        name: 'description_en',
        value: ''
    },
    {
        name: 'description_kz',
        value: ''
    },
    {
        name: 'rules',
        value: ''
    },
    {
        name: 'parent_id',
        value: ''
    },
]

const initialTermFields = [
    {
        name: 'name',
        value: ''
    },
    {
        name: 'description',
        value: ''
    },
    {
        name: 'short_description',
        value: ''
    },
    {
        name: 'term_type_id',
        value: null
    },
    {
        name: 'is_group',
        value: ''
    },
    {
        name: 'group_id',
        value: null
    },
    {
        name: 'category_ids',
        value: []
    },
    {
        name: 'responsibility_on',
        value: null
    },
    {
        name: 'owner',
        value: ''
    },
]




export const InterestContainer = observer((props) => {

    const {
        dispatch,
        terms,
        termTypes,
        users,
        constOptions,
        relationTypes,
        infoIsLoading,
        help,
        history,
        match,
        uploadFileStatus,
        files,
        currentUser
    } = props

    const catId = []
    const view = []
    const term = []
    const termId = []

    // const {view, catId, term, termId, action} = match.params

    const [createSectionModal, setCreateSectionModal] = useState(false)
    const [updateSectionModal, setUpdateSectionModal] = useState(false)
    const [updateSectionFields, setUpdateSectionFields] = useState(initialSectionFields)
    const {t} = useTranslation()


    const [createTermModal, setCreateTermModal] = useState(false)
    const [updateTermModal, setUpdateTermModal] = useState(false)
    const [currentGroupTerms, setCurrentGroupTerms] = useState([])
    const [termFields, setTermFields] = useState(initialTermFields)
    const [isGroup, setIsGroup] = useState(false)
    const [propagateRules, setPropagateRules] = useState([])
    const [applyRules, setApplyRules] = useState([])
    const [uiRules, setUiRules] = useState([])
    const [relations, setRelations] = useState([])
    // const [helpModalVisible, setHelpModalVisible] = useState(false)
    const [currentCategory, setCurrentCategory] = useState(null)
    const [pageType, setPageType] = useState('table')
    const [propagateForm] = Form.useForm();
    const [applyForm] = Form.useForm();
    const [uiForm] = Form.useForm()
    const [relationForm] = Form.useForm()
    const [termTypesForm] = Form.useForm()
    const [termTypeParams, setTermTypeParams] = useState('')
    const [currentTermType, setCurrentTermType] = useState('')
    const [currentTerm, setCurrentTerm] = useState('')
    const [dependsRules, setDependsRules] = useState({
        propagate: {
            level: null,
        },
        apply: {
            level: null,
        },
        ui: {
            level: null,
        }
    })

    // const queryObj = urlToObject(window.location.search)





    const handleSocketSubscribe = (payload) => {
        setCurrentCategory(prev => payload.find(item => item.id === prev?.id))
    }

    const handleSocketChanges = (payload) => {
        const {objects} = payload
        if (objects.some(item => item.type === 'terms')) {
            setCurrentCategory(prev => prev?.map(term => payload.objects.some(item => item.id === term.id) ? {
                ...term,
                status: payload.action.state
            } : term))
        }
    }


    const onCancel = () => {
        setCreateSectionModal(false)
        setUpdateSectionModal(false)
        setUpdateSectionFields(initialSectionFields)

        setCreateTermModal(false)
        setUpdateTermModal(false)
        setTermFields(initialTermFields)
        setIsGroup(false)
        setPropagateRules([])
        setApplyRules([])
        setRelations([])
        setUiRules([])
        propagateForm.resetFields()
        relationForm.resetFields()
        applyForm.resetFields()
        setCurrentTermType('')
        setTermTypeParams('')
        history.push({
            search: ''
        })
        setDependsRules({
            propagate: {
                level: null,
            },
            apply: {
                level: null,
            },
            ui: {
                level: null,
            }
        })
    }

    const onOpenSectionModal = (type, section) => {

        if (type === 'create') {
            setCreateSectionModal(section)
            history.push({
                search: 'create_section=true'
            })
        } else if (type === 'update') {
            const updateData = section
            // updateData.rules = JSON.stringify(updateData.rules)
            section.parent_id = parseInt(section.parent_id)
            setUpdateSectionFields(changeFormFieldsData(initialSectionFields, updateData))
            setUpdateSectionModal(updateData)
        }
    }

    const onDeleteSection = (id) => {
        interestModel.deleteSource(id)
        // dispatch(termsActions.deleteTermCategoryFetch(id))
        setCurrentCategory(null)
    }


    const onSubmitSectionModal = (value) => {
        // if (createSectionModal) {
        //     if (createSectionModal?.id) {
        //         value.parent_id = createSectionModal.id
        //     }
        //     value.rules = JSON.parse(value.rules)
        //     interestModel.create(value)
        // } else if (updateSectionModal) {
        //     value.rules = JSON.parse(value.rules)
        //     interestModel.update(value, updateSectionModal.id)
        // }
        // debugger
        if (createSectionModal) {
            if (value.parent_id) {
                value.parent_id = value.parent_id.toString()
            } else {
                value.parent_id = null
            }
            value.rules = JSON.parse(value.rules)
            interestModel.create(value)
        } else if (updateSectionModal) {
            if (value.parent_id) {
                value.parent_id = value.parent_id.toString()
            } else {
                value.parent_id = null
            }
            value.rules = JSON.parse(value.rules)
            interestModel.update(value, updateSectionModal)
        }


        onCancel()
    }

    const onSelectTermCategory = (category) => {
        setCurrentCategory(category)
        interestModel.addCurrentCategory(category)
        // history.search = category
        // if (pageType === 'table') history.push(`/terms/category/${category.id}/table`)
        // dispatch(termsActions.onSelectTermCategoryParse({...category, terms: addKeysToArray(category.terms, 'term')}))
    }


    //Terms Handlers
    const onOpenTermModal = (type, payload) => {
        if (type === 'create') {
            setCreateTermModal(true)
            const selectedId = terms.selectedTermCategory.id
            history.push({
                search: 'create=true'
            })

            if (selectedId) {
                setTermFields(changeFormFieldsData(initialTermFields, {category_ids: [selectedId]}))
            }
        } else if (type === 'update') {
            // FIXME remove condition for checking array after backend fixing
            setPropagateRules(payload.propagate_rules?.map(item => ({key: uuidv4(), ...item})) ?? [])
            setApplyRules(payload.apply_rules?.map(item => ({key: uuidv4(), ...item})) ?? [])
            setUiRules(payload.ui_rules?.map(item => ({key: uuidv4(), ...item})) ?? [])
            setRelations(payload.relations?.map(item => {
                return {
                    ...item,
                    right_id: `${currentCategory.name}-term-${item.right_id}`,
                    key: uuidv4(),
                }
            }) ?? [])
            setIsGroup(payload.is_group)
            setCurrentTerm(payload)
            const filePayload = {
                type: 'terms',
                id: payload.id
            }
            // dispatch(uploadFileActions.getFileFetch(filePayload))

            setTermFields(changeFormFieldsData(initialTermFields, payload))
            setUpdateTermModal(payload)

            setCurrentTermType(termTypes.find(type => type.id === payload.term_type_id))
            setTermTypeParams(
                payload.params
                    .map(param => {
                        return {
                            key: param.key,
                            name: param.name,
                            value: param.value
                        }
                    })
            )
        }
    }

    const onChangeDependsRules = (type, field, value) => {
        if (field === 'include' || field === 'exclude') {
            if (type === 'propagate') {
                if (field === 'include' && value || field === 'exclude' && !value) {
                    propagateForm.setFieldsValue({exclude: false, include: true})
                } else if (field === 'exclude' && value || field === 'include' && !value) {
                    propagateForm.setFieldsValue({include: false, exclude: true})
                }
            } else if (type === 'apply') {
                if (field === 'include' && value || field === 'exclude' && !value) {
                    applyForm.setFieldsValue({exclude: false, include: true})
                } else if (field === 'exclude' && value || field === 'include' && !value) {
                    applyForm.setFieldsValue({include: false, exclude: true})
                }
            } else if (type === 'ui') {
                if (field === 'include' && value || field === 'exclude' && !value) {
                    uiForm.setFieldsValue({exclude: false, include: true})
                } else if (field === 'exclude' && value || field === 'include' && !value) {
                    uiForm.setFieldsValue({include: false, exclude: true})
                }
            }
        } else {
            setDependsRules(prev => {
                const tempDepends = {...prev}
                tempDepends[type][field] = value
                return tempDepends
            })
        }
    }


    const onChangeRules = (type, key, field, value) => {
        if (type === 'propagate') {
            if (field === 'include' && value || field === 'exclude' && !value) {
                setPropagateRules(prev => prev.map(item => item.key === key ? {
                    ...item,
                    include: true,
                    exclude: false
                } : item))
            } else if (field === 'exclude' && value || field === 'include' && !value) {
                setPropagateRules(prev => prev.map(item => item.key === key ? {
                    ...item,
                    include: false,
                    exclude: true
                } : item))
            } else {
                setPropagateRules(prev => prev.map(item => {
                    if (item.key === key) {
                        const tempRule = item
                        tempRule[field] = value
                        return tempRule
                    }
                    return item
                }))
            }
        } else if (type === 'apply') {
            if (field === 'include' && value || field === 'exclude' && !value) {
                setApplyRules(prev => prev.map(item => item.key === key ? {
                    ...item,
                    include: true,
                    exclude: false
                } : item))
            } else if (field === 'exclude' && value || field === 'include' && !value) {
                setApplyRules(prev => prev.map(item => item.key === key ? {
                    ...item,
                    include: false,
                    exclude: true
                } : item))
            } else {
                setApplyRules(prev => prev.map(item => {
                    if (item.key === key) {
                        const tempRule = item
                        tempRule[field] = value
                        return tempRule
                    }
                    return item
                }))
            }
        }
    }

    const onDeleteRules = (type, key) => {
        if (type === 'propagate') {
            setPropagateRules(prev => prev.filter(item => item.key !== key))
        } else if (type === 'apply') {
            setApplyRules(prev => prev.filter(item => item.key !== key))
        } else if (type === 'ui') {
            setUiRules(prev => prev.filter(item => item.key !== key))
        }
    }

    const onSubmitTerms = (v) => {
        // let relations = []
        //FIXME KILL WINDOW OBJECT
        window.term_type_id = v.term_type_id
        // FIXME need check
        let rels = relations.map(item => {
            if (item.left_side == true) {
                return {
                    key: item.key,
                    left_id: parseInt(item.left_id.split('-')[2]),
                    right_id: location.href.includes('create=true') ? null : window.term_type_id,
                    relation_type_id: item.relation_type_id,
                    left_side: item.left_side

                }
            } else {
                return {
                    key: item.key,
                    right_id: parseInt(item.right_id.split('-')[2]),
                    left_id: location.href.includes('create=true') ? null : window.term_type_id,
                    relation_type_id: item.relation_type_id,
                    left_side: false
                }
            }
        })


        const payload = {
            ...v,
            propagate_rules: propagateRules,
            apply_rules: applyRules,
            ui_rules: uiRules,
            relations: rels
            // relations: relations.map(relation => {
            //
            // 	return {
            // 		...relation,
            // 		right_id: parseInt(relation.right_id.split('-')[2]),
            // 		['left_id' in relation ? 'left_id' : 'right_id']: parseInt(('left_id' in relation ?
            // 			relation.left_id : relation.right_id.split('-')[2])),
            //
            // 	}
            // 	// return {
            // 	// 	...relation,
            // 	// 	['left_id' in relation ? 'left_id' : 'right_id']: parseInt(('left_id' in relation ?
            // 	// 		relation.left_id : relation.right_id).split('-')[2])
            // 	// }
            // 	// FIXME check
            // }),
        }

        const term = {
            ...payload,
            params: (termTypesForm.getFieldsValue().params || []).map(param => {
                return {
                    key: param.key,
                    name: param.name,
                    value: Array.isArray(param.value) ? param.value[0] : param.value
                }
            }),
            child_ids: updateTermModal.child_ids,
            child_term_type_id: updateTermModal.child_term_type_id
        }





        if (createTermModal) {
            // dispatch(termsActions.createTermFetch(term))
        } else if (updateTermModal) {
            // dispatch(termsActions.updateTermFetch(updateTermModal.id, term))
        }
        onCancel()
    }

    const onDeleteTerm = (id) => {
        // dispatch(termsActions.deleteTermFetch(id))
    }

    const goToTermGroupPage = (id) => {
        history.push(`/terms/category/${currentCategory.id}/group/${id}/${view}`)
    }


    const setTypeParams = (value) => {
        setCurrentTermType(termTypes.find(type => type.id === value))
        setTermTypeParams(
            termTypes.find(type => type.id === value).params
                .map(param => {
                    return {
                        key: param.key,
                        name: param.name,
                        value: (createTermModal && param.values === null) ? param.default_value : param.values
                    }
                })
        )
    }

    const refresh = () => {
        window.location.reload()
    }

    const sideBarMenuItems = [
        {
            title: t('Create Interest'),
            icon: <PlusCircleOutlined/>,
            action: () => onOpenSectionModal('create', true),
        },
        {
            title: t('Refresh'),
            icon: <UndoOutlined/>,
            action: refresh
        },
    ]

    const backFromGroup = {
        title: 'Back',
        icon: <RollbackOutlined />,
        action: () => {
            history.push(`/terms/category/${catId}/table`)
        }
    }
    // if (currentGroupTerms !== null || (term === 'term' && termId)) sideBarMenuItems.push(backFromGroup);


    const treeData = interestModel.data



    const sideBarMenuComponentsItems = [
        {
            component: <Tree
                className={'term_tree_menu'}
                switcherIcon={<DownOutlined/>}
                treeData={treeData}
                draggable
                titleRender={dataNode => dataNode.name !== 'Uncategorized' ? <>
                    <div style={{display: 'flex', alignItems: 'center'}} onClick={() => onSelectTermCategory(dataNode)}>
                        {dataNode.name}
                    </div>
                    <div className={'dropdown_trigger'}>
                        <Dropdown.Button overlay={
                            <Menu>
                                    <Menu.Item key="1" onClick={() => onOpenSectionModal('create', dataNode)}
                                               icon={<PlusSquareOutlined/>}>
                                        <Button style={{border: 'none', background: 'unset'}} size={'small'}>
                                            Create sub category
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item key="2" icon={<EditOutlined/>}>
                                        <Button onClick={() => onOpenSectionModal('update', dataNode)}
                                                style={{border: 'none', background: 'unset'}} size={'small'}>
                                            Update category
                                        </Button>
                                    </Menu.Item>
                                    <Menu.Item key="3" icon={<DeleteOutlined/>}>
                                        <Popconfirm
                                            title="Are you sure to delete this Term Category?"
                                            onConfirm={() => onDeleteSection(dataNode.id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button style={{border: 'none', background: 'unset'}} size={'small'}
                                                    danger>
                                                Delete category
                                            </Button>
                                        </Popconfirm>
                                    </Menu.Item>
                            </Menu>
                        }>
                        </Dropdown.Button>
                    </div>
                </> : <div onClick={() => onSelectTermCategory(dataNode)}>{dataNode.name}</div>
                }
            />,
        }
    ]


    useEffect(
        // FIXME useEffect GO TO MODEL!! OR CONTAINER
        () =>
            autorun(() => {
                interestModel.get()
                profileModel.get()
            }),
        [],
    )

    const dataList = interestModel.data


    return (
        <Layout>
            <SideBarComponent
                toggleVisibleMenu={'none'}
                menuItems={sideBarMenuItems}
                // FIXMe
                // sideBarComponents={sideBarMenuComponentsItems}
            />
            <InterestModal
                fields={termFields}
                applyForm={applyForm}
                applyRules={applyRules}
                dependsRules={dependsRules}
                onCancel={onCancel}
                createTermModal={createTermModal}
                constOptions={constOptions}
                onChangeDependsRules={onChangeDependsRules}
                onChangeRules={onChangeRules}
                propagateForm={propagateForm}
                onDeleteRules={onDeleteRules}
                setApplyRules={setApplyRules}
                propagateRules={propagateRules}
                setDependsRules={setDependsRules}
                setPropagateRules={setPropagateRules}
                updateTermModal={updateTermModal}
                onSubmit={onSubmitTerms}
                isGroup={isGroup}
                setIsGroup={setIsGroup}
                relations={relations}
                relationForm={relationForm}
                setRelations={setRelations}
                uiRules={uiRules}
                setUiRules={setUiRules}
                uiForm={uiForm}
                relatedTerms={terms?.relatedTerms}
                termTypes={termTypes}
                termTypeParams={termTypeParams}
                termTypesForm={termTypesForm}
                setTypeParams={setTypeParams}
                currentTermType={currentTermType}
                relationTypes={relationTypes}
                currentTerm={currentTerm}
                dispatch={dispatch}
                uploadFileStatus={uploadFileStatus}
                files={files}
                currentUser={currentUser}
            />
            <SectionModal
                createSectionModal={createSectionModal}
                updateSectionModal={updateSectionModal}
                onCancel={onCancel}
                updateSectionFields={updateSectionFields}
                onSubmitSectionModal={onSubmitSectionModal}
            />
            {/*<HelpModal*/}
            {/*	helpModalVisible={helpModalVisible}*/}
            {/*	setHelpModalVisible={setHelpModalVisible}*/}
            {/*	helpData={help.data.modal}*/}
            {/*	helpName={'terms'}*/}
            {/*/>*/}
            <Card style={{width: '100%'}}>
                <Col span={24}>
                    {
                        <>
                            <Row justify={'space-between'}>
                                <Title level={3}>{t('Interest')}</Title>
                                {/*<Button.Group style={{display: 'flex', gap: 10}}>*/}
                                {/*        <Button*/}
                                {/*            onClick={() => onOpenTermModal('create')}><PlusCircleOutlined/>*/}
                                {/*            Create Site*/}
                                {/*        </Button>*/}
                                {/*        <Button*/}
                                {/*            onClick={() => onOpenSectionModal('update', currentCategory)}>*/}
                                {/*            <EditOutlined/> Update Site*/}
                                {/*        </Button>*/}
                                {/*        <Popconfirm*/}
                                {/*            title="Are you sure to delete this Term Category?"*/}
                                {/*            onConfirm={() => onDeleteSection(currentCategory.id)}*/}
                                {/*            okText="Yes"*/}
                                {/*            cancelText="No"*/}
                                {/*        >*/}
                                {/*            <Button>*/}
                                {/*                <PlusCircleOutlined/> Delete Site*/}
                                {/*            </Button>*/}
                                {/*        </Popconfirm>*/}
                                {/*</Button.Group>*/}
                            </Row>

                            <InterestList
                                // terms={currentCategory?.terms}
                                terms={dataList}
                                relationTypes={relationTypes}
                                users={users}
                                onDeleteTerm={onDeleteTerm}
                                onOpenTermModal={onOpenTermModal}
                                // CRUDStatus={terms.CRUDStatus}
                                goToTermGroupPage={goToTermGroupPage}
                                dispatch={dispatch}
                                currentUser={currentUser}
                                history={history}
                                match={match}
                                onOpenSectionModal={onOpenSectionModal}
                            />

                            {/*{!currentGroupTerms*/}
                            {/*    ?*/}
                            {/*        <SitesCategoryList*/}
                            {/*            // terms={currentCategory?.terms}*/}
                            {/*            terms={sitesModel.categories}*/}
                            {/*            relationTypes={relationTypes}*/}
                            {/*            users={users}*/}
                            {/*            onDeleteTerm={onDeleteTerm}*/}
                            {/*            onOpenTermModal={onOpenTermModal}*/}
                            {/*            // CRUDStatus={terms.CRUDStatus}*/}
                            {/*            goToTermGroupPage={goToTermGroupPage}*/}
                            {/*            dispatch={dispatch}*/}
                            {/*            currentUser={currentUser}*/}
                            {/*            history={history}*/}
                            {/*            match={match}*/}
                            {/*        />*/}

                            {/*    : <SitesCategoryGroupList*/}
                            {/*        currentGroupTerms={currentGroupTerms}*/}
                            {/*        onOpenTermModal={onOpenTermModal}*/}
                            {/*        onDeleteTerm={onDeleteTerm}*/}
                            {/*        // CRUDStatus={terms.CRUDStatus}*/}
                            {/*        users={users}*/}
                            {/*        currentUser={currentUser}*/}
                            {/*        relationTypes={relationTypes}*/}
                            {/*        history={history}*/}
                            {/*        match={match}*/}
                            {/*    />}*/}
                            </>
                    }
                </Col>
            </Card>
        </Layout>
    )
});
