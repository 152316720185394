import React, {useState} from "react"
import Button from "antd/es/button";
import MenuUnfoldOutlined from "@ant-design/icons/MenuUnfoldOutlined";
import MenuFoldOutlined from "@ant-design/icons/MenuFoldOutlined";
import {Card, Menu} from "antd";
import Sider from "antd/es/layout/Sider";
import {Link} from "react-router-dom";
import {Spin} from 'antd';
import {useTranslation} from "react-i18next";


export const SideBarComponent = (props) => {
    const {menuItems, sideBarComponents, status, toggleVisibleMenu, isCollapsed} = props;

    const {t} = useTranslation()

    const [collapsed, setCollapsed] = useState(isCollapsed ?? false);

    const menuComponents = sideBarComponents?.map((item, index) => <Card
        style={{display: collapsed && toggleVisibleMenu}}
        key={`SideBard-Card-${index}`}>{item.component}</Card>)



    return <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        style={{minHeight: 'calc(100vh - 50px)'}}
        className={!collapsed && 'autoWidth_sidebar'}
        trigger={null}
        theme={'dark'}
    >
        <Button type="default" className={'sidebar_toggler'} size={"large"}
                onClick={() => setCollapsed(!collapsed)} style={{marginBottom: 16, width: '100%'}}>
            {collapsed ? <MenuUnfoldOutlined/> :
                <MenuFoldOutlined/>} {collapsed === false ? t('Actions') : ''}
        </Button>

            <Menu
                mode="inline"
                theme={'light'}
                selectedKeys={location.pathname}
            >
                {menuItems?.map((item, index) =>
                    !item.hide &&
                    <Menu.Item
                        className={"inner_edit_sidebar"}
                        disabled={item?.disabled}
                        onClick={() => {
                            item.action && item?.action()
                        }}
                        key={`SideBar-MenuItem-${index}`}
                        icon={item?.icon ?? ""}>
                        {!item?.link ? item?.title
                            :
                            <Link
                                to={item.link ?? ""}
                                target={item.blank}
                                onClick={(e) => {
                                    if (item.action && item.action()) return
                                    document.history.push(item.link)

                                    e.preventDefault()
                                    return false
                                }}
                            >
                                {item?.title ?? ""}
                            </Link>}
                    </Menu.Item>)}
            </Menu>
        {menuComponents}
    </Sider>
}
