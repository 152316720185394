import React, {useEffect} from 'react'
import Table from "antd/es/table";
import {formatDate} from "../../tools/refactorDate";
import {autorun} from "mobx";
import {usersModel} from "../../model/usersModel.js";
import {profileModel} from "../../model/profleModel.js";
import {useState} from "react";

import { observer } from 'mobx-react-lite';
import {auditModel} from "../../model/auditModel.js";

import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


export const AuditList = observer((props) => {

    const {dispatch, data, query} = props

    // FIXME refact to useLocate
// FIXME to container
    const currentPage = parseInt(window.location.href?.split('page=')[1]?.split('&')[0] ?? 1)
    const currentPageSize = parseInt(window.location.href?.split('limit=')[1] ?? 20)

    const navigate = useNavigate()

    const {t} = useTranslation()



    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onChangePage = (page, pageSize = 20) => {
        navigate(`/audit?page=${page}&limit=${pageSize}`)
        auditModel.changePage(page, pageSize)
    }

    const columns = [
        {
            title: t('Username'),
            dataIndex: 'user_name',
            width: '15%'
        },
        {
            title: t('Object Type'),
            dataIndex: 'object_type',
            width: '15%'
        },
        {
            title: t('Object Name'),
            dataIndex: 'object_name',
            width: '15%'
        },
        {
            title: t('Action'),
            dataIndex: 'action',
            width: '15%'
        },
        {
            title: t('Params'),
            dataIndex: 'params',
            width: '40%'
        }
    ];

    useEffect(
        // FIXME useEffect GO TO MODEL!! OR CONTAINER
        () =>
            autorun(() => {
                console.log('useEffect ReRender::')
                auditModel.get()
                usersModel.getUsers()
                profileModel.get()
            }),
        [],
    )

    const audit = auditModel.data.audit

    const tableStyle = {
        marginTop: '0.5em',
    }




    return (
        <Table
            bordered
            dataSource={audit}
            size={'small'}
            columns={columns}
            style={tableStyle}
            rowSelection={rowSelection}

            onChange={pagination => onChangePage(pagination.current, pagination.pageSize)}
            pagination={{
                position: ['bottomCenter'],
                current: currentPage,
                total: auditModel.data.count,
                defaultPageSize: currentPageSize,
                showSizeChanger: true,
                pageSizeOptions: [20, 50, 100, 200]
            }}

            // loading={airblast.CRUDStatus === PENDING}
        />
    )
})
