import cookies from "react-cookies";
// import {authApi} from "../models/authModel/authApi";
// import {Api} from "../constants/api";
import {Api} from "../../constants/api"
import notification from "antd/es/notification";
import axios from "axios"
import {authModel} from "../auth.js";


export const instance = (auth = true) => {

    const axiosInstance = axios.create({
        baseURL: Api.url + Api.version,
    })



    if (auth) {
        const access_token = cookies.load('token').access_token
        cookies.save('access_token', access_token, {path: '/'})
        axiosInstance.defaults.headers = {
            'Authorization': `Bearer ${access_token}`
        }
    }


    axiosInstance.interceptors.response.use((response) => {
        return response;
    }, async (error) => {

        if (error.request.status === 0) {
            notification.error({
                message: "Error",
                description: 'Network error',
                placement: 'topRight'
            });
        } else if (error.response.status === 401 && error.config.url === "/token/refresh") {

            cookies.remove('token', {path: '/'});
            // cookies.remove('token', {path: '/'})
            document.location.reload()

        } else if (error.response.status === 401) {

            try {

                const tokens = cookies.load('token')
                const {access_token} = authModel.refreshTokenApi(tokens.refresh_token)

                if (access_token != undefined) {
                    cookies.save('access_token', access_token, {path: '/'})

                    cookies.save('token', {...tokens, access_token}, {path: '/'})
                }



                axiosInstance.defaults.headers['Authorization'] = "Bearer " + access_token;
                error.config.headers['Authorization'] = "Bearer " + access_token;

                return axiosInstance(error.config)

            } catch (e) {
                cookies.remove('token', {path: '/'});
                document.location.reload()

            }

        }
        notification.error({
            message: error.response.statusText,
            description: `${error.response.data.message}`,
            placement: 'topRight'
        });
        return Promise.reject(error);
    });


    return axiosInstance;
}
