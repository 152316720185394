import { useState } from 'react'
import './App.css'
import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import cookies from "react-cookies";
import { ProfileContainer } from "./pages/Profile/ProfileContainer.jsx";
import {UsersContainer} from "./pages/Users/UsersContainer.jsx";
import {Auth} from './pages/Auth.jsx'
import {Nav} from "./pages/Nav/Nav.jsx";
import {AuditContainer} from "./pages/Audit/AuditContainer.jsx";
import {ClassContainer} from "./pages/Classes/ClassContainer";
import {LangContainer} from "./pages/Lang/LangContainer";
import {CountriesContainer} from "./pages/Countries/CountriesContainer";
import {DomainsContainer} from "./pages/Domains/DomainsContainer";
import {BrandsContainer} from "./pages/Brands/BrandsContainer";
import {BrandSourcesContainer} from "./pages/BrandSources/BrandSourcesContainer";
import {IabContainer} from "./pages/Iab/IabContainer";
import {InterestContainer} from "./pages/Interest/InterestContainer";
import {HostsContainer} from "./pages/Hosts/HostsContainer";
import {HostExternalContainer} from "./pages/HostExternal/HostExternalContainer";

import {SitesContainer} from "./pages/Sites/SitesContainer.jsx";


function App() {
    const flexContainer = {
        display: 'flex',
        maxWidth: '1920px'
    }
    const headerStyle = {
        width: '100%',
        background: '#001529'
    }
    if (cookies.load('token') != undefined && cookies.load('token')?.access_token != '') {
        return (
            <Router>
                <div>
                    <header style={headerStyle}>
                        <Nav class="menu"/>
                    </header>
                    <div style={flexContainer}>
                        <Routes>
                            <Route path="/"  />
                            <Route path="/profile" element={<ProfileContainer />} />
                            <Route path="/audit" element={<AuditContainer />} />
                            <Route path="/hosts" element={<SitesContainer />} />
                            <Route path="users" element={<UsersContainer />} />
                            <Route path="classes" element={<ClassContainer />} />
                            <Route path="languages" element={<LangContainer />} />
                            <Route path="countries" element={<CountriesContainer />} />
                            <Route path="domain-zones" element={<DomainsContainer />} />
                            <Route path="brand-sources" element={<BrandSourcesContainer />} />
                            <Route path="brands" element={<BrandsContainer />} />
                            <Route path="iab" element={<IabContainer />} />
                            <Route path="interest" element={<InterestContainer />} />
                            <Route path="hosts-taxonomy" element={<HostsContainer />} />
                            <Route path="hosts-sources" element={<HostExternalContainer />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        )
    } else {
        return (
            <Auth />
        )
    }
}

export default App
