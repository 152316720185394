import { makeAutoObservable } from 'mobx';
import {instance} from "./api/api.jsx";

export const profileModel = makeAutoObservable({
   data: [],
   status: false,
   current: null,
   error: null,

   get: async () => {
      try {
         const payload = await instance().get('/profile').then(res => res.data)
         profileModel.data = payload.data
      } catch (e) {
         console.log('usersModel CATCH', e)
      }
   },

});
