import React from 'react';
import Layout from 'antd/es/layout';
import UndoOutlined from '@ant-design/icons/UndoOutlined';
import { Profile } from './Profile';
import { SideBarComponent } from "../SideBar/SideBarComponent.jsx";

const sideBarMenuItems = [
    {
        title: 'Refresh',
        icon: <UndoOutlined />,
        action: () => {}
    }
];

export const ProfileContainer = ({ currentUser }) => {
    const onSubmitHandle = (data) => {
        data.id = currentUser.id;
    };

    return (
        <>
            <SideBarComponent isCollapsed={true} menuItems={sideBarMenuItems} />
            <Layout className={'contentLayout'}>
                <h2
                    className="site-page-header layout_page_header"
                >
                Personal Data
                </h2>


                <Profile
                    user={currentUser}
                    onSubmit={onSubmitHandle}
                ></Profile>
            </Layout>
        </>
    );
};
